import {connect, DispatchProp, MapStateToProps} from "react-redux"
import {RouteComponentProps, withRouter} from "react-router"
import {All, ObjectState, Status} from "../../redux/globalState"
import * as React from "react"
import {style} from "typestyle"
import axios, {AxiosError, AxiosResponse} from "axios"
import {Categories} from "../../model/model"
import {fetchCategories, removeLessonLocally, showTempNotification} from "../../redux/actionCreators"
import {goBack, replace} from "react-router-redux"
import ObjectStateDisplay from "../../component/ObjectStateDisplay"
import SubGoalEditComponent from "./SubGoalEditComponent"
import {SubGoalEdit} from "./SubGoalEdit"
import {CategoryAction} from "../../redux/reducers"

type Props = {
    categoryState: ObjectState<Categories>,
} & DispatchProp<any> & RouteComponentProps<any>

class LessonNewPage extends React.Component<Props, {}> {

    constructor(props: Props) {
        super(props)
        document.title = "Matematikplanen - opret Delmål"
    }

    componentDidMount() {
        this.props.dispatch(fetchCategories(this.props.categoryState))
    }

    categoryShowUrl(id: string, period: number) {
        return `/category?id=${id}&period=${period}`
    }

    render() {
        return (
            <ObjectStateDisplay
                state={this.props.categoryState}
                content={(categories: Categories) => (
                    <div className={style({margin: 16})}>
                        <h1>Opret et nyt delmål</h1>
                        <SubGoalEditComponent
                            categories={categories}
                            submit={(subGoalEdit: SubGoalEdit) => {
                                axios.post(`/subgoal`, subGoalEdit)
                                    .then((response: AxiosResponse) => {
                                        switch (response.status) {
                                            case 200:
                                                const categoryAction: CategoryAction = {
                                                    type: "CATEGORIES_REMOVE",
                                                }
                                                this.props.dispatch(categoryAction)
                                                this.props.dispatch(replace(
                                                    this.categoryShowUrl(response.data.id, response.data.period)))
                                                this.props.dispatch(showTempNotification("Delmål oprettet"))
                                                break
                                            default:
                                                throw Error("unexpected code")
                                        }
                                    })
                                    .catch((error: AxiosError) => {
                                        alert("Der skete en fejl - delmålet er ikke gemt")
                                    })
                            }}
                        />
                    </div>
                )}
            />
        )
    }

}

const mapStateToProps: MapStateToProps<{categoryState: ObjectState<Categories>}, void, All> = (state: All) => ({
    categoryState: state.categoryState,
})
export default withRouter(connect(mapStateToProps)(LessonNewPage))



