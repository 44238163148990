import {connect, DispatchProp, MapStateToProps} from "react-redux"
import {RouteComponentProps, withRouter} from "react-router"
import {All, ObjectState, Status} from "../../redux/globalState"
import * as React from "react"
import {style} from "typestyle"
import axios, {AxiosError, AxiosResponse} from "axios"
import {Lesson} from "../../model/lessonModel"
import LessonEditComponent from "./LessonEditComponent"
import {Categories} from "../../model/model"
import {deleteLesson, fetchCategories, removeLessonLocally, showTempNotification} from "../../redux/actionCreators"
import {Link} from "react-router-dom"
import Button from "@material-ui/core/Button"
import AxiosErrorView from "../../component/AxiosErrorView"
import LoadingView from "../../component/LoadingView"

type Props = {
    categoryState: ObjectState<Categories>,
} & DispatchProp<any> & RouteComponentProps<any>
type State = {
    objectState: ObjectState<Lesson>,
    updatedTimestamp: Date | null,
}

class LessonEditPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            objectState: {
                status: Status.NONE,
                object: null,
            },
            updatedTimestamp: null,
        }
        document.title = "Matematikplanen - editer Lektion"
    }


    componentDidMount() {
        if (this.state.objectState.status !== Status.LOADING && this.state.objectState.status !== Status.SUCCESS) {
            axios.get(`/lessons/raw/${this.props.match.params.id}`)
                .then((response: AxiosResponse) => {
                    const object: Lesson = response.data
                    this.setState({
                        objectState: {
                            status: Status.SUCCESS,
                            object,
                        },
                    })
                }).catch((error: AxiosError) => {
                this.setState({
                    objectState: {
                        status: Status.ERROR,
                        object: null,
                        error,
                    },
                })
            })
        }
        this.props.dispatch(fetchCategories(this.props.categoryState))
    }

    render() {
        if (this.state.objectState.status === Status.ERROR) {
            return (
                <AxiosErrorView error={this.state.objectState.error} />
            )
        } else if (this.props.categoryState.status === Status.ERROR) {
            return (
                <AxiosErrorView error={this.props.categoryState.error} />
            )
        } else if (
            this.state.objectState.status === Status.LOADING || this.state.objectState.status === Status.NONE ||
            this.props.categoryState.status === Status.LOADING || this.props.categoryState.status === Status.NONE
        ) {
            return (
                <LoadingView />
            )
        }
        const object: Lesson = this.state.objectState.object

        return (
            <div className={style({margin: 16})}>
                <Button
                    component={(props: any) => (<Link to={`/lesson/${this.props.match.params.id}`} {...props} />)}
                    color="primary"
                >
                    Vis
                </Button>

                <h1> Editing Lektion </h1>
                <LessonEditComponent
                    lesson={object}
                    categories={this.props.categoryState.object}
                    submit={(lesson: Lesson) => {
                        this.setState({
                            objectState: {
                                status: Status.LOADING,
                                object: null,
                            },
                        })
                        this.props.dispatch(removeLessonLocally(this.props.match.params.id))

                        axios.put(`/lessons/${this.props.match.params.id}`, lesson)
                            .then((response: AxiosResponse) => {
                                switch (response.status) {
                                    case 200:
                                        this.setState({
                                            objectState: {
                                                status: Status.SUCCESS,
                                                object: response.data,
                                            },
                                            updatedTimestamp: new Date(),
                                        })
                                        this.props.dispatch(showTempNotification("Lektion gemt"))
                                        break
                                    default:
                                        throw Error("unexpected code")
                                }
                            })
                            .catch((error: AxiosError) => {
                                this.setState({
                                    objectState: {
                                        status: Status.ERROR,
                                        object: null,
                                        error,
                                    },
                                })
                            })
                    }}
                />
                {this.state.updatedTimestamp != null ?
                    <div>
                        Sidst opdateret: {this.state.updatedTimestamp.toDateString()}
                    </div> : null
                }
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                        if (confirm("Er du sikker?")) {
                            this.props.dispatch(deleteLesson(this.props.match.params.id))
                        }
                    }}
                >
                    Slet
                </Button>
            </div>
        )

    }

}

const mapStateToProps: MapStateToProps<{categoryState: ObjectState<Categories>}, void, All> = (state: All) => ({
    categoryState: state.categoryState,
})
export default withRouter(connect(mapStateToProps)(LessonEditPage))



