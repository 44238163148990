import {connect, DispatchProp, MapStateToProps} from "react-redux"
import {RouteComponentProps, withRouter} from "react-router"
import {All, ObjectState, Status} from "../../redux/globalState"
import * as React from "react"
import {style} from "typestyle"
import axios, {AxiosError, AxiosResponse} from "axios"
import {Lesson} from "../../model/lessonModel"
import LessonEditComponent from "./LessonEditComponent"
import {Categories} from "../../model/model"
import {fetchCategories, removeLessonLocally, showTempNotification} from "../../redux/actionCreators"
import {replace} from "react-router-redux"
import ObjectStateDisplay from "../../component/ObjectStateDisplay"

type Props = {
    categoryState: ObjectState<Categories>,
} & DispatchProp<any> & RouteComponentProps<any>

class LessonNewPage extends React.Component<Props, {}> {

    constructor(props: Props) {
        super(props)
        document.title = "Matematikplanen - opret Lektion"
    }

    componentDidMount() {
        this.props.dispatch(fetchCategories(this.props.categoryState))
    }

    render() {
        return (
            <ObjectStateDisplay
                state={this.props.categoryState}
                content={(categories: Categories) => (
                    <div className={style({margin: 16})}>
                        <h1>Opret en ny lektion</h1>
                        <LessonEditComponent
                            categories={categories}
                            submit={(lesson: Lesson) => {
                                axios.post(`/lessons`, lesson)
                                    .then((response: AxiosResponse) => {
                                        switch (response.status) {
                                            case 200:
                                                this.props.dispatch(removeLessonLocally(this.props.match.params.id))
                                                this.props.dispatch(showTempNotification("Lektion oprettet"))
                                                this.props.dispatch(replace(`/lesson/${response.data.id}`))
                                                break
                                            default:
                                                throw Error("unexpected code")
                                        }
                                    })
                                    .catch((error: AxiosError) => {
                                        alert("Der skete en fejl - lektionen er ikke gemt")
                                    })
                            }}
                        />
                    </div>
                )}
            />
        )
    }

}

const mapStateToProps: MapStateToProps<{categoryState: ObjectState<Categories>}, void, All> = (state: All) => ({
    categoryState: state.categoryState,
})
export default withRouter(connect(mapStateToProps)(LessonNewPage))



