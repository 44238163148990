import {routerReducer, routerMiddleware} from "react-router-redux"
import * as redux from "redux"
import createHistory from "history/createBrowserHistory"
import * as React from "react"
import * as globalState from "./globalState"
import {authTokenHeader} from "../const/const"
import ReduxThunk from "redux-thunk"
import {loginActionCreator} from "./actionCreators"
import * as jwtDecoder from "jwt-decode"
import {Categories, TokenData} from "../model/model"
import {Lesson} from "../model/lessonModel"
import {LessonsState, ObjectState, Status, TempNotificationState} from "./globalState"
import {Theory} from "../model/theoryModel"
import {AxiosError} from "axios"

export type AuthenticationAction =
    {type: "CLEAR_STATE"} |
    {type: "SET_STATE_FROM_TOKEN", authToken: string}
const authenticationReducer: (state: globalState.Authentication, action: AuthenticationAction) =>
    globalState.Authentication = (
        state: globalState.Authentication = {tokenData: null, isAdmin: false},
        action: AuthenticationAction,
) => {
    if (action.type === "CLEAR_STATE") {
        return {tokenData: null, isAdmin: false}
    } else if (action.type === "SET_STATE_FROM_TOKEN") {
        const tokenDecoded: TokenData = jwtDecoder(action.authToken)
        let isAdmin = false
        for (const role of tokenDecoded.roles) {
            if (role === "EDITOR" || role === "ADMIN") {
                isAdmin = true
            }
        }
        return {
            tokenData: tokenDecoded,
            isAdmin,
        }
    } else {
        return state
    }
}

export type CategoryAction =
    {type: "CATEGORIES_LOAD"} |
    {type: "CATEGORIES_ERROR", error: AxiosError} |
    {type: "CATEGORIES_SUCCESS", categories: Categories} |
    {type: "CATEGORIES_REMOVE"}
const categoryReducer =
    (state: ObjectState<Categories> = {status: Status.NONE, object: null},
     action: CategoryAction): ObjectState<Categories> => {
        switch (action.type) {
            case "CATEGORIES_LOAD":
                return {status: Status.LOADING, object: state.object}
            case "CATEGORIES_ERROR":
                return {status: Status.ERROR, object: null, error: action.error}
            case "CATEGORIES_SUCCESS":
                return {status: Status.SUCCESS, object: action.categories}
            case "CATEGORIES_REMOVE":
                return {status: Status.NONE, object: null}
            default:
                return state
        }
    }

export type TheoryAction =
    {type: "THEORY_LOAD"} |
    {type: "THEORY_ERROR", error: AxiosError} |
    {type: "THEORY_SUCCESS", theory: Theory}
const theoryReducer =
    (state: ObjectState<Theory> = {status: Status.NONE, object: null},
     action: TheoryAction): ObjectState<Theory> => {
        switch (action.type) {
            case "THEORY_LOAD":
                return {status: Status.LOADING, object: state.object}
            case "THEORY_ERROR":
                return {status: Status.ERROR, object: null, error: action.error}
            case "THEORY_SUCCESS":
                return {status: Status.SUCCESS, object: action.theory}
            default:
                return state
        }
    }

export type LessonAction =
    {type: "LESSON_LOAD", id: string} |
    {type: "LESSON_ERROR", id: string, error: AxiosError} |
    {type: "LESSON_SUCCESS", id: string, lesson: Lesson} |
    {type: "LESSON_REMOVE", id: string}
const lessonReducer =
    (state: LessonsState = {},
     action: LessonAction): LessonsState => {
        const nextState = Object.assign({}, state)

        if (action.type === "LESSON_REMOVE") {
            delete nextState[action.id]
            return nextState
        }

        let newLessonObject: ObjectState<Lesson>
        if (action.type === "LESSON_LOAD") {
            const object = state[action.id] != null ? state[action.id].object : null
            newLessonObject = {status: Status.LOADING, object}
        } else if (action.type === "LESSON_ERROR") {
            newLessonObject = {status: Status.ERROR, object: null, error: action.error}
        } else if (action.type === "LESSON_SUCCESS") {
            newLessonObject = {status: Status.SUCCESS, object: action.lesson}
        } else {
            return state
        }
        nextState[action.id] = newLessonObject
        return nextState
    }

export type TempNotificationAction =
    {type: "TEMP_NOTIFICATION_CLEAR"} |
    {type: "TEMP_NOTIFICATION_SET", message: string}
const tempNotificationReducer = (
    state: TempNotificationState = null,
    action: TempNotificationAction): TempNotificationState => {
    switch (action.type) {
        case "TEMP_NOTIFICATION_CLEAR":
            return null
        case "TEMP_NOTIFICATION_SET":
            return action.message
        default:
            return state
    }
}

export const rootReducer: redux.Reducer<globalState.All> = redux.combineReducers({
    authentication: authenticationReducer,
    router: routerReducer,
    categoryState: categoryReducer,
    lessonsState: lessonReducer,
    theoryState: theoryReducer,
    tempNotification: tempNotificationReducer,
})

const history = createHistory()
// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history)
const store: any =
    redux.createStore(rootReducer, redux.applyMiddleware(ReduxThunk, middleware))
const token = localStorage.getItem(authTokenHeader)
if (token != null) {
    store.dispatch(loginActionCreator(token, location.pathname + location.search))
}

export const createReduxStore = () => ({
    history,
    store,
})
