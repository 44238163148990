import * as React from "react"
import {style} from "typestyle"
import {ReactElement} from "react"

export default (props: {
    label: string,
    children: ReactElement<any> | Array<ReactElement<any>>,
    smallPadding?: boolean,
}) => (
    <div
        className={style({padding: props.smallPadding ? 2 : 16, display: "flex"})}
    >
        <span
            className={style({width: 180})}
        >
            {props.label}:
        </span>
        <div>{props.children}</div>
    </div>
)
