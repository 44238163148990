import * as React from "react"
import axios from "axios"
import {connect, DispatchProp} from "react-redux"
import {loginActionCreator} from "../redux/actionCreators"
import {authTokenHeader} from "../const/const"
import ErrorText from "../component/ErrorText"
import {classes, style} from "typestyle"
import {NavLink} from "react-router-dom"
import Button from "@material-ui/core/Button"

type State = {
    username: string,
    password: string,
    showWrongCredentials: boolean,
}

class LoginPage extends React.Component<DispatchProp<any>, State> {

    constructor(props: any) {
        super(props)
        this.state = {
            username: "",
            password: "",
            showWrongCredentials: false,
        }
        document.title = "Velkommen til Matematikplanen"
    }

    login: () => void = () => {
        // const nextPath = queryString.parse(this.props.history.location.search).nextPath as string
        this.setState({
            showWrongCredentials: false,
        })
        const credentials = {
            username: this.state.username,
            password: this.state.password,
        }
        axios.post("/session", credentials)
            .then((response) => {
                switch (response.status) {
                    case 200:
                        const token = response.headers[authTokenHeader]
                        this.props.dispatch(loginActionCreator(token, null))
                        break
                    default:
                        throw Error("unexpected code")

                }
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 401:
                        this.setState({showWrongCredentials: true})
                        break
                    default:
                        throw Error("unexpected code")
                }
            })
    }

    render() {
        return (
            <div
                className={style({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                })}
            >
                <h1
                    className={style({
                        marginTop: "40px",
                    })}
                >
                    Velkommen til matematikplanen
                </h1>
                <h3
                    className={style({
                        textAlign: "center",
                    })}
                >
                    Matematiklærerens eget arbejdsredskab til udvikling af en matematikundervisning,
                    <br/>
                    der henvender sig til børn.
                </h3>
                <div
                    className={style({
                        width: 400,
                        marginTop: 64,
                        marginLeft: 120,
                    })}
                >
                    <ErrorText
                        text={"Forkert password eller brugernavn"}
                        show={this.state.showWrongCredentials}
                    />
                    <table>
                        <tbody>
                            <tr>
                                <td><label>Brugernavn:</label></td>
                                <td>
                                    <UsernameInput
                                        value={this.state.username}
                                        onChange={(newValue: string) => {
                                            this.setState({
                                                username: newValue,
                                            })
                                        }}
                                        onEnter={this.login}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><label>Password:</label></td>
                                <td>
                                    <PasswordInput
                                        value={this.state.password}
                                        onChange={(newValue: string) => {
                                            this.setState({
                                                password: newValue,
                                            })
                                        }}
                                        onEnter={this.login}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        className={style({
                            marginTop: 16,
                            marginLeft: 90,
                        })}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.login}
                        >
                            Log ind
                        </Button>
                    </div>
                    <p
                        className={style({
                            marginTop: 40,
                        })}
                    >
                        Er du ikke oprettet som bruger? <NavLink to="/user/new">Opret dig som bruger her.</NavLink>
                    </p>
                </div>
            </div>

        )
    }
}

const UsernameInput = (props: {value: string, onChange: (newValue: string) => void, onEnter: () => void}) => (
    <input
        type="text"
        value={props.value}
        onChange={(e) => {
            props.onChange(e.target.value)
        }}
        name="username"
        onKeyPress={(event: any) => {
            if (event.key === "Enter") {
                props.onEnter()
            }
        }}
    />
)

const PasswordInput = (props: {value: string, onChange: (newValue: string) => void, onEnter: () => void}) => (
    <input
        type="password"
        value={props.value}
        onChange={(e) => {
            props.onChange(e.target.value)
        }}
        name="passwd"
        onKeyPress={(event: any) => {
            if (event.key === "Enter") {
                props.onEnter()
            }
        }}
    />
)

export default connect()(LoginPage)
