import {connect, DispatchProp} from "react-redux"
import {RouteComponentProps, withRouter} from "react-router"
import {ObjectState, Status} from "../redux/globalState"
import * as React from "react"
import {style} from "typestyle"
import axios, {AxiosError, AxiosResponse} from "axios"
import Button from "@material-ui/core/Button"
import ObjectStateDisplay from "../component/ObjectStateDisplay"
import {deleteLesson, showTempNotification} from "../redux/actionCreators"

type User = {
    id: string,
    username: string,
    fullname: string,
    fiveLastLogIns: string[],
    school: string,
    email: string,
}
type Users = {
    users: User[],
}

type Props = DispatchProp<any> & RouteComponentProps<any>
type State = {
    objectState: ObjectState<Users>,
}

class UsersPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            objectState: {
                status: Status.NONE,
                object: null,
            },
        }
        document.title = "Matematikplanen - brugeroversigt"
    }


    componentDidMount() {
        this.fetchDate()
    }

    fetchDate() {
        this.setState({
            objectState: {
                status: Status.LOADING,
                object: null,
            },
        })
        axios.get(`/users`)
            .then((response: AxiosResponse) => {
                const object: Users = response.data
                this.setState({
                    objectState: {
                        status: Status.SUCCESS,
                        object,
                    },
                })
            }).catch((error: AxiosError) => {
            this.setState({
                objectState: {
                    status: Status.ERROR,
                    object: null,
                    error,
                },
            })
        })

    }

    render() {
        return (
            <ObjectStateDisplay
                state={this.state.objectState}
                content={(object: Users) => {
                    return (
                        <div className={style({margin: 16})}>
                            <h1>Brugeroversigt</h1>
                            {object.users.map((user, index) => (
                                <div
                                    key={index}
                                    className={style(
                                        {
                                            flexDirection: "column",
                                            display: "flex",
                                            paddingLeft: 10,
                                            paddingRight: 22,
                                        },
                                        index % 2 === 0 ? {backgroundColor: "#FAF9F9"} : false,
                                    )}
                                >
                                    <div
                                        className={style(
                                            {
                                                flexDirection: "row",
                                                display: "flex",
                                                height: 42,
                                            },
                                        )}
                                    >
                                        <TableCell
                                            label={user.fullname}
                                            width={30}
                                            textAlign="left"
                                        />
                                        <TableCell
                                            label={user.username}
                                            width={100}
                                            textAlign="left"
                                        />
                                        <TableCell
                                            label={user.email}
                                            width={100}
                                            textAlign="left"
                                        />
                                        <TableCell
                                            label={user.school}
                                            flex={4}
                                            width={100}
                                            textAlign="left"
                                        />
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={() => {
                                                if (confirm("Er du sikker?")) {
                                                    this.setState({
                                                        objectState: {
                                                            status: Status.LOADING,
                                                            object: null,
                                                        },
                                                    })
                                                    axios.delete(`/users/${user.id}`)
                                                        .then((response: AxiosResponse) => {
                                                            this.props.dispatch(showTempNotification("Bruger slettet"))
                                                            this.fetchDate()
                                                        })
                                                        .catch((error: AxiosError) => {
                                                            this.props.dispatch(showTempNotification("Noget gik galt"))
                                                            this.fetchDate()
                                                        })
                                                }
                                            }}
                                        >
                                            Slet
                                        </Button>

                                    </div>
                                    <div
                                        className={style(
                                            {
                                                flexDirection: "row",
                                                display: "flex",
                                                height: 42,
                                            },
                                        )}
                                    >
                                        <TableCell
                                            label={""}
                                            flex={2}
                                            width={0}
                                            textAlign="left"
                                        />
                                        <TableCell
                                            label={user.fiveLastLogIns[0]}
                                            width={50}
                                            textAlign="left"
                                        />
                                        <TableCell
                                            label={user.fiveLastLogIns[1]}
                                            width={50}
                                            textAlign="left"
                                        />
                                        <TableCell
                                            label={user.fiveLastLogIns[3]}
                                            width={50}
                                            textAlign="left"
                                        />
                                        <TableCell
                                            label={user.fiveLastLogIns[4]}
                                            width={50}
                                            textAlign="left"
                                        />
                                        <TableCell
                                            label={user.fiveLastLogIns[5]}
                                            width={50}
                                            textAlign="left"
                                        />
                                        <TableCell
                                            label={""}
                                            flex={2}
                                            width={0}
                                            textAlign="left"
                                        />
                                    </div>

                                </div>
                            ))}
                        </div>
                    )
                }}
            />
        )
    }
}

export default withRouter(connect()(UsersPage))

const TableCell =
    (props: {
        label: string,
        width: number | string,
        flex?: number,
        textAlign?: "left" | "right" | "center",
    }) => (
        <div
            className={style({
                    display: "flex",
                    marginLeft: 10,
                    alignItems: "center",
                    justifyContent: (props.textAlign === "right" ? "flex-end" :
                        (props.textAlign === "center" ? "center" : "flex-start")),
                },
                widthAndFlexGrowStyles(props.width, props.flex),
            )}
        >
            <TableCellLabel label={props.label}/>
        </div>
    )

const TableCellLabel = (props: { label: string }) => (
    <span
        className={style({
            fontSize: 12,
            opacity: 0.5,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        })}
    >
        {props.label}
    </span>
)

const widthAndFlexGrowStyles = (width: number | string, flex?: number) =>
    flex ? {
        flexGrow: flex,
        width,
        paddingLeft: 4,
        paddingRight: 4,
    } : {
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 4,
        paddingRight: 4,
        width,
    }
