import * as React from "react"
import {AxiosError} from "axios"
import {style} from "typestyle"

export default (props: {error: AxiosError}) => {
    let message: string = ""
    if (props.error.response == null) {
        message = "Ingen forbindelse til serveren. Tjek at du har internet og prøv igen"
    } else {
        const statusCode = props.error.response.status
        if (statusCode === 404) {
            message = `Siden blev ikke fundet`
        } else {
            message = `Der skete en fejl. Prøv igen senere. Fejlkode=${statusCode}`
        }
    }
    return (
        <h2
            className={style({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
            })}
        >
            {message}
        </h2>
    )
}

