import * as React from "react"
import {style} from "typestyle"
import {CategoryReference, File, Lesson, NewFile} from "../../model/lessonModel"
import EditRow from "../../component/EditRow"
import {
    Checkbox,
    Checkboxes,
    Dropdown, DropdownIncludingNull,
    NumberInput,
    TextArea,
    TextInput,
} from "../../component/inputs"
import Button from "@material-ui/core/Button"
import {Categories, Category, MainCategory, SubCategory, SubGoal} from "../../model/model"
import {SubGoalEdit} from "./SubGoalEdit"

type Props = {
    subGoal?: SubGoalEdit,
    categories: Categories
    submit: (subGoal: SubGoalEdit) => void,
}
type State = {
    draft: Draft,
    errorMessage: string | null,
}

type Draft = {
    subCategory: CategoryReference | null,
    title: string,
    period: number | null,
    order: number | null,
    description: string,
}

export default class SubGoalEditComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        const emptyDraft: Draft = {
            subCategory: null,
            title: "",
            period: null,
            order: null,
            description: "",
        }

        this.state = {
            draft: props.subGoal != null ? ({
                subCategory: props.subGoal.subCategory,
                title: props.subGoal.title,
                period: props.subGoal.period,
                order: props.subGoal.order,
                description: props.subGoal.description,
            }) : emptyDraft,
            errorMessage: null,
        }
        document.title = "Matematikplanen - editer Lektion"
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
        if (nextProps.subGoal == null) {
            throw Error()
        }
        this.setState({
            draft: {
                subCategory: nextProps.subGoal.subCategory,
                title: nextProps.subGoal.title,
                period: nextProps.subGoal.period,
                order: nextProps.subGoal.order,
                description: nextProps.subGoal.title,
            },
            errorMessage: null,
        })
    }

    subCategoryOptions(categories: Categories): CategoryReference[] {
        let result: CategoryReference[] = []
        for (const mainCategory of categories.mainCategories) {
            const subCategories = mainCategory.subCategories
            if (subCategories) {
                result = result.concat(subCategories.map((category: SubCategory) => ({
                    id: category.id,
                    title: category.title,
                    url: "",
                })))
            }
        }
        return result
    }

    render() {
        const draft: Draft = this.state.draft
        const subCategoryOptions = this.subCategoryOptions(this.props.categories)

        return (
            <div>
                <EditRow
                    label="Underkategori"
                >
                    <DropdownIncludingNull
                        value={draft.subCategory}
                        options={subCategoryOptions}
                        valueToDisplayString={(c) => c.title}
                        onChange={(category: CategoryReference | null) => {
                            draft.subCategory = category
                            this.setState({
                                draft,
                            })
                        }}
                        disabled={false}
                    />
                </EditRow>
                <EditRow
                    label="Titel"
                >
                    <TextArea
                        value={draft.title}
                        onChange={(newValue) => {
                            draft.title = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={2}
                    />
                </EditRow>
                <EditRow
                    label="Periode"
                >
                    <NumberInput
                        value={draft.period}
                        onChange={(newValue) => {
                            draft.period = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                    />
                </EditRow>
                <EditRow
                    label="Rækkefølge"
                >
                    <NumberInput
                        value={draft.order}
                        onChange={(newValue) => {
                            draft.order = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                    />
                </EditRow>
                <EditRow
                    label="Beskrivelse"
                >
                    <TextArea
                        value={draft.description}
                        onChange={(newValue) => {
                            draft.description = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={20}
                    />
                </EditRow>

                <div
                    className={style({
                        marginTop: 16,
                        marginBottom: 16,
                    })}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (draft.subCategory == null) {
                                alert("Underkatogori skal være sat")
                                return
                            }
                            if (draft.title === null) {
                                alert("Titel skal være sat")
                                return
                            }
                            if (draft.period === null || draft.period < 1 || draft.period > 7) {
                                alert("Periode skal være sat")
                                return
                            }
                            if (draft.order === null) {
                                alert("Order skal være sat")
                                return
                            }
                            const subGoalEdit: SubGoalEdit = {
                                subCategory: draft.subCategory,
                                title: draft.title,
                                period: draft.period,
                                order: draft.order,
                                description: draft.description,
                            }
                            this.props.submit(subGoalEdit)
                        }}
                    >
                        {this.props.subGoal ? "Opdater" : "Opret"}
                    </Button>
                </div>
            </div>
        )

    }
}
