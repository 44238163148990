import {connect, DispatchProp, MapStateToProps} from "react-redux"
import {RouteComponentProps, withRouter} from "react-router"
import {All, ObjectState, Status} from "../redux/globalState"
import * as React from "react"
import axios, {AxiosError, AxiosResponse} from "axios"
import {Theory} from "../model/theoryModel"
import {NavLink} from "react-router-dom"
import {style} from "typestyle"
import ObjectStateDisplay from "../component/ObjectStateDisplay"
import {TheoryAction} from "../redux/reducers"

type Props = {
    theoryState: ObjectState<Theory>,
} & DispatchProp<TheoryAction> & RouteComponentProps<any>

class TheoryPage extends React.Component<Props, {}> {

    constructor(props: Props) {
        super(props)
        document.title = "Matematikplanen - teori"
    }

    componentDidMount() {
        if (this.props.theoryState.status === Status.LOADING || this.props.theoryState.status === Status.SUCCESS) {
            return
        }

        this.props.dispatch({type: "THEORY_LOAD"})
        axios.get("/theory")
            .then((response: AxiosResponse) => {
                const theory: Theory = new Theory(response.data, null)
                this.props.dispatch({type: "THEORY_SUCCESS", theory})
            }).catch((error: AxiosError) => {
                this.props.dispatch({type: "THEORY_ERROR", error})
        })
    }

    render() {
        return (
            <ObjectStateDisplay
                state={this.props.theoryState}
                content={(rootTheory: Theory) => {
                    const theory = this.findTheoryByUrlParam(rootTheory, this.props.match.params.title)
                    if (theory === null) {
                        return (
                            <div>No theory page with given url</div>
                        )
                    }
                    return (
                        <div>
                            <NavigationBar previous={theory.previous()} next={theory.next()} />
                            <div
                                className={style({
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                })}
                            >
                                <h1
                                    className={style({
                                        width: 1200,
                                        textAlign: "center",
                                        marginBottom: 32,
                                    })}
                                >
                                    {theory.title}
                                </h1>
                                <Content theory={theory}/>
                            </div>
                            <NavigationBar previous={theory.previous()} next={theory.next()} />
                        </div>
                    )
                }}
            />
        )
    }

    findTheoryByUrlParam(root: Theory, urlParam: string): Theory | null {
        if (root.url === urlParam) {
            return root
        }
        for (const c of root.subSections) {
            const foundChild = this.findTheoryByUrlParam(c, urlParam)
            if (foundChild !== null) {
                return foundChild
            }
        }
        return null
    }
}

const mapStateToProps: MapStateToProps<{theoryState: ObjectState<Theory>}, void, All> = (state: All) => ({
    theoryState: state.theoryState,
})
export default withRouter(connect(mapStateToProps)(TheoryPage))

const NavigationBar = (props: {previous: Theory | null, next: Theory | null}) => (
    <div
        className={style({
            display: "flex",
            padding: 32,
        })}
    >
        { props.previous !== null &&
            <NavLink
                to={`/theory/${props.previous.url}`}
            >
                Forrige
            </NavLink>
        }
        <span
            className={style({flexGrow: 1})}
        />
        { props.next !== null &&
            <NavLink
                to={`/theory/${props.next.url}`}
            >
                Næste
            </NavLink>
        }
    </div>
)

const Content = (props: {theory: Theory}) => (
    <div
    >
        {
            props.theory.subSections
                .filter((t: Theory) => t.showInIndex)
                .map((t: Theory) => <TheoryIndex key={t.url} theory={t} />)
        }
        <div
            className={style({width: 550})}
            dangerouslySetInnerHTML={{__html: props.theory.text}}
        />
        {
            props.theory.subSections
                .filter((t: Theory) => ! t.showInIndex)
                .map((t: Theory) => <SubTheoryText key={t.url} theory={t} />)
        }
    </div>
)

const TheoryIndex: React.SFC<{theory: Theory}> = (props) => (
    <ul>
        <li><NavLink to={`/theory/${props.theory.url}`}>{props.theory.title}</NavLink></li>
        {
            props.theory.subSections
                .filter((t: Theory) => t.showInIndex)
                .map((t: Theory) => <TheoryIndex key={t.url} theory={t}/>)
        }
    </ul>
)

const SubTheoryText = (props: {theory: Theory}) => (
    <div>
        <h2>{props.theory.title}</h2>
        <p>{props.theory.text}</p>
        {props.theory.subSections.map((t: Theory) => <SubSubTheoryText key={t.url} theory={t}/>)}
    </div>
)

const SubSubTheoryText: React.SFC<{theory: Theory}> = (props) => (
    <div>
        <h2>{props.theory.title}</h2>
        <p>{props.theory.text}</p>
        {props.theory.subSections.map((t: Theory) => <SubSubTheoryText key={t.url} theory={t}/>)}
    </div>
)
