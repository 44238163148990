import * as React from "react"

export const TextInput =
    (props: {
        value: string | null,
        onChange: (newValue: string | null) => void,
        size?: number,
        disabled?: boolean,
        password?: boolean,
    }) => (
    <input
        type={props.password ? "password" : "text"}
        value={props.value != null ? props.value : ""}
        onChange={(e) => {
            props.onChange(e.target.value === "" ? null : e.target.value)
        }}
        size={props.size ? props.size : 100}
        disabled={props.disabled ? true : false}
    />
)

export const NumberInput =
    (props: {
        value: number | null,
        onChange: (newValue: number | null) => void,
    }) => (
        <input
            type="number"
            value={props.value != null ? props.value : ""}
            onChange={(e) => {
                if (e.target.value === "") {
                    props.onChange(null)
                } else {
                    props.onChange(parseInt(e.target.value, 10))
                }
            }}
            style={{width: 50}}
        />
    )

export const TextArea =
    (props: {
        value: string,
        onChange: (newValue: string | null) => void,
        rows?: number,
    }) => (
        <textarea
            value={props.value}
            onChange={(e) => {
                props.onChange(e.target.value)
            }}
            cols={100}
            rows={props.rows}
        />
    )

export const Dropdown =
    <T extends {}>(props: {
        value: T,
        options: T[],
        valueToDisplayString: (t: T) => string,
        onChange: (newValue: T) => void,
        disabled: boolean,
    }) => {
    const customOnChange = (stringValue: string) => {
        let newValue: T | null = null
        props.options.forEach((option) => {
            if (props.valueToDisplayString(option) === stringValue) {
                newValue = option
            }
        })
        if (newValue == null) {
            throw new Error("AssertionError - selected value not found in options")
        }
        props.onChange(newValue)
    }

    return (
        <select
            value={props.valueToDisplayString(props.value)}
            onChange={(e) => {customOnChange(e.target.value)}}
            disabled={props.disabled}
        >
            {
                props.options.map((option: T, i: number) => {
                    return (
                        <option
                            key={i}
                            value={props.valueToDisplayString(option)}

                        >
                            {props.valueToDisplayString(option)}
                        </option>

                    )
                })
            }
        </select>
    )
}

export const DropdownIncludingNull =
    <T extends {}>(props: {
        value: T | null,
        options: T[],
        valueToDisplayString: (t: T) => string,
        onChange: (newValue: T | null) => void,
        disabled: boolean,
    }) => {
        const value: string = props.value != null ? props.valueToDisplayString(props.value) : ""
        const options: string[] = [""].concat(props.options.map((t: T) => props.valueToDisplayString(t)))
        const onChange = (newValue: string) => {
            if (newValue === "") {
                props.onChange(null)
            } else {
                props.options.forEach((option) => {
                    if (props.valueToDisplayString(option) === newValue) {
                        props.onChange(option)
                    }
                })
            }
        }

        return (
            <Dropdown
                value={value}
                options={options}
                valueToDisplayString={(it) => it}
                onChange={onChange}
                disabled={props.disabled}
            />
        )
    }


export const Checkbox =
    (props: {
        value: boolean,
        text: string,
        onChange: (newValue: boolean) => void,
    }) => (
        <div>
            <input
                value={props.text}
                checked={props.value}
                type="checkbox"
                onChange={(e) => {
                    props.onChange(e.target.checked)
                }}
            />
            <label>{props.text}</label>
        </div>
    )

export const Checkboxes =
    <T extends {}>(props: {
        value: T[],
        options: T[],
        valueToDisplayString: (value: T) => string,
        onChange: (newValue: T[]) => void,
        disabled: boolean,
    }) => (
        <div>
            {
                props.options.map((option: T, i: number) => {
                    const customOnChange = (checked: boolean) => {
                        // let newValue: T[] = []
                        let newValue: T[]
                        if (checked) {
                            const selected: T[] = [option]
                            newValue = ([] as T[]).concat(props.value, selected)
                        } else {
                            newValue = props.value.filter(
                                (v) => (props.valueToDisplayString(v) !== props.valueToDisplayString(option)))
                        }
                        props.onChange(newValue)
                    }

                    return (
                        <div
                            key={i}
                        >
                            <input
                                key={i}
                                value={props.valueToDisplayString(option)}
                                type="checkbox"
                                checked={
                                    props.value.filter(
                                        (v: T) => (
                                            props.valueToDisplayString(v) === props.valueToDisplayString(option)
                                        )).length > 0
                                }
                                onChange={(e) => {customOnChange(e.target.checked)}}
                                disabled={props.disabled}
                            />
                            <label>
                                {props.valueToDisplayString(option)}
                            </label>
                        </div>
                    )
                })
            }
        </div>
    )
