import * as React from "react"
import {style} from "typestyle"
import CircularProgress from "@material-ui/core/CircularProgress"

export default (props: {}) => {
    return (
        <div
            className={style({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
            })}
        >
            <CircularProgress
                color="primary"
                thickness={7}
                size={60}
            />
        </div>
    )
}

