import {connect, DispatchProp, MapStateToProps} from "react-redux"
import {RouteComponentProps, withRouter} from "react-router"
import {All, ObjectState, Status} from "../../redux/globalState"
import * as React from "react"
import {style} from "typestyle"
import axios, {AxiosError, AxiosResponse} from "axios"
import {Categories} from "../../model/model"
import {fetchCategories, showTempNotification} from "../../redux/actionCreators"
import {Link} from "react-router-dom"
import Button from "@material-ui/core/Button"
import AxiosErrorView from "../../component/AxiosErrorView"
import LoadingView from "../../component/LoadingView"
import {SubGoalEdit} from "./SubGoalEdit"
import SubGoalEditComponent from "./SubGoalEditComponent"
import {goBack, replace, RouterAction} from "react-router-redux"
import {CategoryAction} from "../../redux/reducers"

type Props = {
    categoryState: ObjectState<Categories>,
} & DispatchProp<any> & RouteComponentProps<any>
type State = {
    objectState: ObjectState<SubGoalEdit>,
    updatedTimestamp: Date | null,
}

class SubGoalEditPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            objectState: {
                status: Status.NONE,
                object: null,
            },
            updatedTimestamp: null,
        }
        document.title = "Matematikplanen - editer Delmål"
    }


    componentDidMount() {
        if (this.state.objectState.status !== Status.LOADING && this.state.objectState.status !== Status.SUCCESS) {
            axios.get(`/subgoal/${this.props.match.params.id}`)
                .then((response: AxiosResponse) => {
                    const object: SubGoalEdit = response.data
                    this.setState({
                        objectState: {
                            status: Status.SUCCESS,
                            object,
                        },
                    })
                }).catch((error: AxiosError) => {
                this.setState({
                    objectState: {
                        status: Status.ERROR,
                        object: null,
                        error,
                    },
                })
            })
        }
        this.props.dispatch(fetchCategories(this.props.categoryState))
    }

    categoryShowUrl(period: number) {
        return this.categoryShowUrlWithId(this.props.match.params.id, period)
    }

    categoryShowUrlWithId(id: string, period: number) {
        return `/category?id=${id}&period=${period}`
    }

    render() {
        if (this.state.objectState.status === Status.ERROR) {
            return (
                <AxiosErrorView error={this.state.objectState.error} />
            )
        } else if (this.props.categoryState.status === Status.ERROR) {
            return (
                <AxiosErrorView error={this.props.categoryState.error} />
            )
        } else if (
            this.state.objectState.status === Status.LOADING || this.state.objectState.status === Status.NONE ||
            this.props.categoryState.status === Status.LOADING || this.props.categoryState.status === Status.NONE
        ) {
            return (
                <LoadingView />
            )
        }
        const object: SubGoalEdit = this.state.objectState.object

        return (
            <div className={style({margin: 16})}>
                <Button
                    component={(props: any) => (
                        <Link to={this.categoryShowUrl(object.period)} {...props} />)}
                    color="primary"
                >
                    Vis
                </Button>

                <h1>Editer delmål</h1>
                <SubGoalEditComponent
                    subGoal={object}
                    categories={this.props.categoryState.object}
                    submit={(subGoal: SubGoalEdit) => {
                        this.setState({
                            objectState: {
                                status: Status.LOADING,
                                object: null,
                            },
                        })
                        this.props.dispatch({type: "CATEGORIES_REMOVE"} as CategoryAction)
                        axios.put(`/subgoal/${this.props.match.params.id}`, subGoal)
                            .then((response: AxiosResponse) => {
                                switch (response.status) {
                                    case 200:
                                        this.setState({
                                            objectState: {
                                                status: Status.SUCCESS,
                                                object: response.data,
                                            },
                                            updatedTimestamp: new Date(),
                                        })
                                        this.props.dispatch(replace(
                                            this.categoryShowUrl(response.data.period)))
                                        this.props.dispatch(showTempNotification("Delmål gemt"))
                                        break
                                    default:
                                        throw Error("unexpected code")
                                }
                            })
                            .catch((error: AxiosError) => {
                                this.setState({
                                    objectState: {
                                        status: Status.ERROR,
                                        object: null,
                                        error,
                                    },
                                })
                            })
                    }}
                />
                {this.state.updatedTimestamp != null ?
                    <div>
                        Sidst opdateret: {this.state.updatedTimestamp.toDateString()}
                    </div> : null
                }
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                        if (confirm("Er du sikker?")) {
                            axios.delete(`/subgoal/${this.props.match.params.id}`)
                                .then((response: AxiosResponse) => {
                                    switch (response.status) {
                                        case 200:
                                            const categoryAction: CategoryAction = {
                                                type: "CATEGORIES_REMOVE",
                                            }
                                            this.props.dispatch(categoryAction)
                                            this.props.dispatch(showTempNotification("Delmålet er slettet"))
                                            this.props.dispatch(replace(
                                                this.categoryShowUrlWithId(
                                                    object.subCategory.id, object.period)))
                                            break
                                        default:
                                            throw Error("expected 200 status code")
                                    }
                                })
                                .catch((error: AxiosError) => {
                                    alert("Noget gik galt - delmålet blev ikke gemt")
                                })
                        }
                    }}
                >
                    Slet
                </Button>
            </div>
        )
    }
}

const mapStateToProps: MapStateToProps<{categoryState: ObjectState<Categories>}, void, All> = (state: All) => ({
    categoryState: state.categoryState,
})
export default withRouter(connect(mapStateToProps)(SubGoalEditPage))



