import * as React from "react"
import {landingPageActionCreator} from "../redux/actionCreators"
import {connect, DispatchProp} from "react-redux"

class LandingPage extends React.Component<DispatchProp<any>, any> {

    componentDidMount() {
        this.props.dispatch(landingPageActionCreator())
    }

    render() {
        return (
            <div/>
        )
    }
}

export default connect()(LandingPage)
