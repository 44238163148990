export type TokenData = {
    userId: string,
    username: string,
    roles: Role[],
}

export type Role = "TEACHER" | "EDITOR" | "ADMIN"

export class ShortLesson {
    id: string
    level: number
    title: string
    extendedTitle: string
    summary: string
    important: boolean

    constructor(json: any) {
        this.id = json.id
        this.level = json.level
        this.title = json.title
        this.extendedTitle = json.extendedTitle
        this.summary = json.summary
        this.important = json.important
    }
}

export type Category = {
    id: string;
    title: string;
}

export class SubGoal implements Category {
    id: string
    title: string
    description: string
    lessons: ShortLesson[]

    constructor(json: any) {
        this.id = json.id
        this.title = json.title
        this.description = json.description
        this.lessons = json.lessons.map((l: any) => new ShortLesson(l))
    }
}

export class SubCategory implements Category {
    id: string
    title: string
    subGoals: SubGoal[][] | null
    lessons: ShortLesson[][] | null

    constructor(json: any) {
        this.id = json.id
        this.title = json.title
        if (json.subGoals != null) {
            this.subGoals = json.subGoals.map(
                (subGoalsInPeriod: any) => (subGoalsInPeriod.map((subGoal: any) => new SubGoal(subGoal))))
        } else {
            this.subGoals = null
        }
        if (json.lessons != null) {
            this.lessons = json.lessons.map(
                (lessonsInPeriod: any) => (lessonsInPeriod.map((lesson: any) => new ShortLesson(lesson))))
        } else {
            this.lessons = null
        }
    }

    lessonDescendants(period: number): ShortLesson[] {
        if (this.lessons != null) {
            return this.lessons![period - 1]
        }

        let result: ShortLesson[] = []
        this.subGoals![period - 1].forEach((goal) => {
            result = result.concat(goal.lessons)
        })
        result = removeDuplicates(result)
        sortLessons(result)
        return result
    }

    goals(period: number): SubGoal[] | null {
        if (this.subGoals == null) {
            return null
        }
        return this.subGoals[period - 1]
    }
}

export class MainCategory implements Category {
    id: string
    title: string
    subCategories: SubCategory[] | null
    lessons: ShortLesson[][] | null

    constructor(json: any) {
        this.id = json.id
        this.title = json.title
        if (json.subCategories != null) {
            this.subCategories = json.subCategories.map((subCategory: any) => new SubCategory(subCategory))
        } else {
            this.subCategories = null
        }
        if (json.lessons != null) {
            this.lessons = json.lessons.map(
                (lessonsInPeriod: any) => (lessonsInPeriod.map((lesson: any) => new ShortLesson(lesson))))
        } else {
            this.lessons = null
        }
    }

    lessonDescendants(period: number): ShortLesson[] {
        if (this.lessons != null) {
            return this.lessons[period - 1]
        }

        let result: ShortLesson[] = []
        this.subCategories!.forEach((subCategory) => {
            result = result.concat(subCategory.lessonDescendants(period))
        })
        result = removeDuplicates(result)
        sortLessons(result)
        return result
    }

    goals(period: number): SubGoal[] | null {
        if (this.subCategories == null) {
            return null
        }
        let result: SubGoal[] = []
        for (const s of this.subCategories) {
            const sGoals = s.goals(period)
            if (sGoals == null) {
                return null
            }
            result = result.concat(sGoals)
        }
        return result
    }
}

export class Categories {
    mainCategories: MainCategory[]
    competences: Category[]

    constructor(json: any) {
        this.mainCategories = json.mainCategories.map((jsonMainCat: any) => new MainCategory(jsonMainCat))
        this.competences = json.competences
    }
}


const removeDuplicates = (lessons: ShortLesson[]): ShortLesson[] => {
    const result: ShortLesson[] = []
    lessons.forEach((lessonToInsert) => {
        let foundInResult = false
        result.forEach((lesson) => {
            if (lesson.id === lessonToInsert.id) {
                foundInResult = true
            }
        })
        if (!foundInResult) {
            result.push(lessonToInsert)
        }
    })
    return result
}

const sortLessons = (lessons: ShortLesson[]): void => {
    lessons.sort((a, b) => {
        if (a.level < b.level) {
            return -1
        } else if (a.level > b.level) {
            return 1
        } else {
            return 0
        }
    })
}
