import * as React from "react"
import axios from "axios"
import {connect, DispatchProp} from "react-redux"
import {loginActionCreator} from "../redux/actionCreators"
import {authTokenHeader} from "../const/const"
import ErrorText from "../component/ErrorText"
import {classes, style} from "typestyle"
import {NavLink} from "react-router-dom"
import Button from "@material-ui/core/Button"
import {Simulate} from "react-dom/test-utils"
import input = Simulate.input
import EditRow from "../component/EditRow"
import {TextInput} from "../component/inputs"

type State = {
    input: NewUserRequest,
    passwordRepeated: string,
    errorMessage: string | null,
}

type NewUserRequest = {
    signUpCode: string,
    username: string,
    fullname: string,
    school: string,
    email: string,
    password: string,
}

class LoginPage extends React.Component<DispatchProp<any>, State> {

    constructor(props: any) {
        super(props)
        this.state = {
            input: {
                signUpCode: "",
                username: "",
                fullname: "",
                school: "",
                email: "",
                password: "",
            },
            passwordRepeated: "",
            errorMessage: null,
        }
        document.title = "Matematikplanen - opret ny bruger"
    }

    createUser(request: NewUserRequest, passwordRepeated: string) {
        let nextErrorMessage: string | null = null
        if (
            request.signUpCode === null || request.username === null || request.fullname === null ||
            request.school === null || request.email === null || request.password === null || passwordRepeated === null
        ) {
            nextErrorMessage = "Alle felter skal udfyldes"
        }
        if (request.password !== passwordRepeated) {
            nextErrorMessage = "password og gentag password er ikke ens"
        }
        this.setState({
            errorMessage: nextErrorMessage,
        })
        if (nextErrorMessage != null) {
            return
        }

        axios.post("/user", request)
            .then((response) => {
                switch (response.status) {
                    case 200:
                        const token = response.headers[authTokenHeader]
                        this.props.dispatch(loginActionCreator(token, null))
                        break
                    default:
                        throw Error("unexpected code")

                }
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 406:
                        this.setState({
                            errorMessage: "Du har ikke indtastet den korrekte kode (koden fra Jesper eller Peter)",
                        })
                        return
                    case 409:
                        this.setState({
                            errorMessage: "Brugernavnet er optaget",
                        })
                        return
                    default:
                        this.setState({
                            errorMessage: "Der er sket en uventet fejl",
                        })
                        return
                }
            })
    }

    render() {
        const textInputSize = 40

        return (
            <div
                className={style({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                })}
            >
                <h1
                    className={style({
                        marginTop: 40,
                        marginBottom: 16,
                    })}
                >
                    Opret din bruger
                </h1>
                <h3
                    className={style({
                        textAlign: "center",
                    })}
                >
                    Systemet er under udvikling. I øjeblikket kan du kun oprette en konto, <br/>
                    hvis du har fået en kode fra Jesper Folkmar eller Peter Muller.
                </h3>
                <div
                    className={style({
                        marginTop: 32,
                    })}
                >
                    <ErrorText
                        text={this.state.errorMessage!}
                        show={this.state.errorMessage != null}
                    />
                    <EditRow
                        label="Kode (fra Jesper eller Peter)"
                    >
                        <TextInput
                            value={this.state.input.signUpCode}
                            onChange={(newValue) => {
                                this.state.input.signUpCode = newValue!
                                this.setState({
                                    input: this.state.input,
                                })
                            }}
                            size={textInputSize}
                        />
                    </EditRow>
                    <EditRow
                        label="Brugernavn"
                    >
                        <TextInput
                            value={this.state.input.username}
                            onChange={(newValue) => {
                                this.state.input.username = newValue!
                                this.setState({
                                    input: this.state.input,
                                })
                            }}
                            size={textInputSize}
                        />
                    </EditRow>
                    <EditRow
                        label="Dit fulde navn"
                    >
                        <TextInput
                            value={this.state.input.fullname}
                            onChange={(newValue) => {
                                this.state.input.fullname = newValue!
                                this.setState({
                                    input: this.state.input,
                                })
                            }}
                            size={textInputSize}
                        />
                    </EditRow>
                    <EditRow
                        label="Skole"
                    >
                        <TextInput
                            value={this.state.input.school}
                            onChange={(newValue) => {
                                this.state.input.school = newValue!
                                this.setState({
                                    input: this.state.input,
                                })
                            }}
                            size={textInputSize}
                        />
                    </EditRow>
                    <EditRow
                        label="Email"
                    >
                        <TextInput
                            value={this.state.input.email}
                            onChange={(newValue) => {
                                this.state.input.email = newValue!
                                this.setState({
                                    input: this.state.input,
                                })
                            }}
                            size={textInputSize}
                        />
                    </EditRow>
                    <EditRow
                        label="Password"
                    >
                        <TextInput
                            value={this.state.input.password}
                            onChange={(newValue) => {
                                this.state.input.password = newValue!
                                this.setState({
                                    input: this.state.input,
                                })
                            }}
                            size={textInputSize}
                            password={true}
                        />
                    </EditRow>
                    <EditRow
                        label="Gentag password"
                    >
                        <TextInput
                            value={this.state.passwordRepeated}
                            onChange={(newValue) => {
                                this.setState({
                                    passwordRepeated: newValue!,
                                })
                            }}
                            size={textInputSize}
                            password={true}
                        />
                    </EditRow>

                </div>
                <div
                    className={style({
                        marginTop: 16,
                        marginLeft: 90,
                    })}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            this.createUser(this.state.input, this.state.passwordRepeated)
                        }}
                    >
                        Opret bruger
                    </Button>
                </div>
            </div>

        )
    }
}

export default connect()(LoginPage)
