import {AuthenticationAction, CategoryAction, LessonAction, TempNotificationAction} from "./reducers"
import {Action, AnyAction, Dispatch} from "redux"
import {authTokenHeader, defaultSignedInUrl} from "../const/const"
import {push, replace, RouterAction} from "react-router-redux"
import * as globalState from "./globalState"
import {Status} from "./globalState"
import axios, {AxiosError, AxiosResponse} from "axios"
import {Categories} from "../model/model"
import {ObjectState} from "./globalState"

export const clearState: () => AuthenticationAction = () => ({type: "CLEAR_STATE"})
const setStateFromToken: (token: string) => AuthenticationAction = (token) => ({authToken: token,
    type: "SET_STATE_FROM_TOKEN"})

export const loginActionCreator = (authToken: string, nextPath: string | null) => {
    return (dispatch: Dispatch<AuthenticationAction | RouterAction>) => {
        localStorage.setItem(authTokenHeader, authToken)
        dispatch(setStateFromToken(authToken))
        dispatch(replace(nextPath ? nextPath : defaultSignedInUrl))
    }
}

export const logoutActionCreator = (nextPath?: string) => {
    return (dispatch: Dispatch<RouterAction>) => {
        localStorage.removeItem(authTokenHeader)
        dispatch(push((nextPath && nextPath !== "/") ? `/login?nextPath=${nextPath}` : "/login"))
    }
}

export const landingPageActionCreator = () => {
    return (dispatch: Dispatch<RouterAction>) => {
        if (localStorage.getItem(authTokenHeader)) {
            dispatch(replace(defaultSignedInUrl))
        } else {
            dispatch(replace("/login"))
        }
    }
}

export const showTempNotification = (message: string) => {
    return (dispatch: Dispatch<TempNotificationAction>) => {
        dispatch({type: "TEMP_NOTIFICATION_SET", message})
    }
}
export const clearTempNotification = () => {
    return (dispatch: Dispatch<TempNotificationAction>) => {
        dispatch({type: "TEMP_NOTIFICATION_CLEAR"})
    }
}

export const removeLessonLocally = (lessonId: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({type: "LESSON_REMOVE", id: lessonId})
        dispatch({type: "CATEGORIES_REMOVE"})
        dispatch(fetchCategories({status: Status.NONE, object: null}))
    }
}

// restClient
export const deleteLesson = (lessonId: string) => {
    return (dispatch: Dispatch<any>) => {
        axios.delete(`/lessons/${lessonId}`)
            .then((response: AxiosResponse) => {
                switch (response.status) {
                    case 200:
                        dispatch(showTempNotification("Lektionen er slettet"))
                        dispatch(removeLessonLocally(lessonId))
                        dispatch(replace("/category"))
                        break
                    default:
                        throw Error("expected 200 status code")
                }
            })
            .catch((error: AxiosError) => {
                dispatch({type: "LESSON_ERROR", id: lessonId, error})
                alert("Noget gik galt - Lektionen blev ikke gemt")
            })
    }
}

export const fetchCategories = (categoryState: ObjectState<Categories>) => {
    return (dispatch: Dispatch<CategoryAction>) => {
        if (categoryState.status !== Status.LOADING && categoryState.status !== Status.SUCCESS) {
            dispatch({type: "CATEGORIES_LOAD"})
            axios.get("/categories")
                .then((response) => {
                    const categories: Categories = new Categories(response.data)
                    dispatch({type: "CATEGORIES_SUCCESS", categories})
                }).catch((error: AxiosError) => {
                    dispatch({type: "CATEGORIES_ERROR", error})
            })
        }
    }
}
