import "babel-polyfill" // Required for IE
import * as React from "react"
import * as ReactDOM from "react-dom"
import {Route, Router, Switch} from "react-router-dom"
import LoginPage from "./page/LoginPage"
import CategoriesPage from "./page/CategoriesPage"
import {createReduxStore} from "./redux/reducers"
import {Provider} from "react-redux"
import {setupAxiosInterceptors} from "./config/axiosConfig"
import {style} from "typestyle"
import Topbar from "./component/Topbar"
import LandingPage from "./page/LandingPage"
import {createStylesheet} from "./util/Stylesheet"
import TheoryPage from "./page/TheoryPage"
import AdminPage from "./page/AdminPage"
import LessonPage from "./page/LessonPage"
import TheoryEditPage from "./page/TheoryEditPage"
import LessonEditPage from "./page/lessonEdit/LessonEditPage"
import LessonNewPage from "./page/lessonEdit/LessonNewPage"
import TempNotification from "./component/TempNotification"
import SubGoalNewPage from "./page/subGoalEdit/SubGoalNewPage"
import SubGoalEditPage from "./page/subGoalEdit/SubGoalEditPage"
import NewUserPage from "./page/NewUserPage"
import UsersPage from "./page/UsersPage"

const App = () => (
    <Switch>
        <Route exact={true} path="/" component={LandingPage}/>
        <Route exact={true} path="/login" component={LoginPage}/>
        <Route exact={true} path="/user/new" component={NewUserPage}/>
        {/*<Route path="/" render={renderPrivateRoute(SignedInPage)}/>*/}
        <Route path="/" render={SignedInPage}/>
    </Switch>
)

const SignedInPage = () => (
    <div
        className={style({
            display: "flex",
            flexDirection: "row",
        })}
    >
        <div
            className={style({
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
            })}
        >
            <Topbar/>
            <Switch>
                <Route exact={true} path="/category" component={CategoriesPage}/>
                <Route exact={true} path="/theory/:title" component={TheoryPage}/>
                <Route exact={true} path="/theory/edit/:id" component={TheoryEditPage}/>
                <Route exact={true} path="/subgoal/new" component={SubGoalNewPage}/>
                <Route exact={true} path="/subgoal/edit/:id" component={SubGoalEditPage}/>
                <Route exact={true} path="/lesson/new" component={LessonNewPage}/>
                <Route exact={true} path="/lesson/edit/:id" component={LessonEditPage}/>
                <Route exact={true} path="/lesson/:id" component={LessonPage}/>
                <Route exact={true} path="/administration" component={AdminPage}/>
                <Route exact={true} path="/users" component={UsersPage}/>
            </Switch>
        </div>
        <TempNotification />
    </div>
)

const {history, store} = createReduxStore()
setupAxiosInterceptors(store)

createStylesheet()


ReactDOM.render(
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    ,
    document.getElementById("root"),
)
