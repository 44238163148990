import * as React from "react"
import {clearTempNotification} from "../redux/actionCreators"
import {connect, DispatchProp} from "react-redux"
import {All} from "../redux/globalState"
import Snackbar from "@material-ui/core/Snackbar/Snackbar"

type Props = {
    tempNotification: string,
} & DispatchProp<any>

const mapStateToProps = (state: All) => ({
    tempNotification: state.tempNotification ? state.tempNotification : null,
})
export default connect(mapStateToProps)(
    class extends React.Component<Props, {}> {

        render() {
            return (
                <div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        open={this.props.tempNotification != null}
                        autoHideDuration={5000}
                        onClose={() => {
                            this.props.dispatch(clearTempNotification())
                        }}
                        ContentProps={{"aria-describedby": "message-id"}}
                        message={<span id="message-id">{this.props.tempNotification}</span>}
                    />
                </div>
            )
        }
    })

