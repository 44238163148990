import axios from "axios"
import {AxiosRequestConfig, AxiosResponse} from "axios"
import {authTokenHeader} from "../const/const"
import {logoutActionCreator} from "../redux/actionCreators"
import * as redux from "redux"
import * as globalState from "../redux/globalState"

export const setupAxiosInterceptors = (store: any) => {
    const onRequestSuccess = (config: AxiosRequestConfig) => {
        const token = localStorage.getItem(authTokenHeader)
        if (token) {
            config.headers[authTokenHeader] = token
        }
        config.url = `/api${config.url}`
        return config
    }
    const onResponseSuccess = (response: AxiosResponse) => {
        const token = response.headers[authTokenHeader]
        if (token) {
            localStorage.setItem(authTokenHeader, token)
        }
        return response
    }
    const onResponseError = (error: any) => {
        if (error.response.status === 403 || error.response.status === 401) {
            store.dispatch(logoutActionCreator())
        }
        return Promise.reject(error)
    }
    axios.interceptors.request.use(onRequestSuccess)
    axios.interceptors.response.use(onResponseSuccess, onResponseError)
}
