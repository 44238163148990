import * as React from "react"
import {logoutActionCreator} from "../redux/actionCreators"
import {connect, DispatchProp} from "react-redux"
import {style} from "typestyle"
import {All, Authentication} from "../redux/globalState"
import {NavLink, NavLinkProps} from "react-router-dom"
import {RouteComponentProps, withRouter} from "react-router"

type Props = {
    authentication: Authentication;
} & DispatchProp<any> & RouteComponentProps<any>
type State = {
    isOpen: boolean;
}

const mapStateToProps = (state: All) => ({
    authentication: state.authentication,
})
export default withRouter(connect(mapStateToProps)(
    class extends React.Component<Props, State> {


        handleLogOutClick = () => {
            this.props.dispatch(logoutActionCreator())
        }

        render() {
            return (
                <div
                    className={style({
                        marginTop: 4,
                    })}
                >
                    <div
                        className={style({
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            flexGrow: 1,
                            height: 32,
                        })}
                    >
                        <TopbarLink to={"/category"} text={"Lektioner"}/>
                        <TopbarLink to={"/theory/Indhold"} text={"Teori"}/>
                        <TopbarLink to={"/theory/Kompetencer"} text={"Kompetencer"}/>
                        <TopbarLink to={"/theory/Vejledning"} text={"Vejledning"}/>
                        <TopbarLink to={"/theory/Materialelister"} text={"Materialelister"}/>
                        {this.props.authentication.isAdmin ?
                            <TopbarLink to={"/administration"} text={"Editer indhold"}/>
                            : null
                        }
                        <span style={{flexGrow: 1}} />
                        {this.props.authentication.tokenData ?
                            <b
                                style={{
                                    marginRight: 8,
                                }}
                            >
                                {this.props.authentication.tokenData.username}
                            </b>
                            : null
                        }
                        {this.props.authentication.tokenData ?
                            <a
                                onClick={this.handleLogOutClick}
                                href=""
                                style={{
                                    marginRight: 8,
                                }}
                            >
                                Log af
                            </a> : null
                        }
                    </div>
                    <hr/>
                </div>
            )
        }
    }))

const TopbarLink = (props: NavLinkProps & {text: string}) => (
    <NavLink
        to={props.to}
        activeClassName={style({
            fontWeight: "bold",
        })}
        style={{
            marginLeft: 8,
        }}
    >
        {props.text}
    </NavLink>
)
