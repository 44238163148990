import {connect, DispatchProp, MapStateToProps} from "react-redux"
import {RouteComponentProps, withRouter} from "react-router"
import {All, LessonsState, ObjectState, Status} from "../redux/globalState"
import * as React from "react"
import axios, {AxiosError, AxiosResponse} from "axios"
import {Lesson, File, CategoryReference} from "../model/lessonModel"
import {NavLink} from "react-router-dom"
import {style} from "typestyle"
import ObjectStateDisplay from "../component/ObjectStateDisplay"
import {LessonAction} from "../redux/reducers"

type Props = {
    lessonsState: LessonsState,
    isAdmin: boolean,
} & DispatchProp<LessonAction> & RouteComponentProps<any>

class LessonPage extends React.Component<Props, {}> {

    constructor(props: Props) {
        super(props)
        document.title = "Matematikplanen - lektion"
    }

    componentDidMount() {
        const id: string = this.props.match.params.id
        const lessonState: ObjectState<Lesson> =
            this.props.lessonsState[id] != null ? this.props.lessonsState[id] : ({status: Status.NONE, object: null})


        if (lessonState.status === Status.LOADING || lessonState.status === Status.SUCCESS) {
            return
        }

        this.props.dispatch({type: "LESSON_LOAD", id})
        axios.get(`/lessons/${id}`)
            .then((response: AxiosResponse) => {
                const lesson: Lesson = response.data
                this.props.dispatch({type: "LESSON_SUCCESS", id, lesson})
            }).catch((error: AxiosError) => {
                this.props.dispatch({type: "LESSON_ERROR", id, error})
            })
    }

    render() {
        const id: string = this.props.match.params.id
        const lessonState: ObjectState<Lesson> =
            this.props.lessonsState[id] != null ? this.props.lessonsState[id] : ({status: Status.NONE, object: null})
        return (
            <ObjectStateDisplay
                state={lessonState}
                content={(lesson) => (
                    <div
                        className={style({
                            width: 750,
                            border: "thin solid black",
                            padding: 10,
                            paddingBottom: 16,
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: 16,
                        })}
                    >
                        <div>
                            {this.props.isAdmin ?
                                <NavLink to={`/lesson/edit/${id}`}>
                                    ret i lektionens indhold
                                </NavLink> : null
                            }
                            <h2>{lesson.extendedTitle}</h2>
                            {
                                lesson.mainCategory.title === "Evaluering"
                                || lesson.mainCategory.title === "Supplerende materialer" ? null : (
                                    <span>
                                <h3 className={style({marginBottom: 16})}>
                                    Faglige mål
                                </h3>
                                <ul>
                                    <CategoryLinks categories={lesson.subCategories}/>
                                    <CategoryLinks categories={lesson.competences}/>
                                    <CategoryLinks categories={lesson.secondaryCategories}/>
                                </ul>
                            </span>
                                )
                            }
                            <HeaderAndContent header="Lektionsmål" text={lesson.lessonObject} href="/theory/Maal"/>
                            <HeaderAndContent header="Arbejdsform" text={lesson.workform}/>
                            <HeaderAndContent
                                header="Materialer"
                                text={lesson.materials}
                                files={lesson.materialFiles}
                                href="/theory/Materialer"
                            />
                            <HeaderAndContent header="Ord, udtryk og symboler" text={lesson.terminology}/>
                            <HeaderAndContent header="Startaktivitet" text={lesson.startActivity}/>
                            <HeaderAndContent header="Aktivitet" text={lesson.activity}/>
                            <HeaderAndContent header="Differentiering / variationer" text={lesson.variations}/>
                            <HeaderAndContent
                                header="Supplerende aktiviteter"
                                text={lesson.supplement}
                                files={lesson.supplementFiles}
                            />
                            <HeaderAndContent header="Afslutning" text={lesson.ending}/>
                            <HeaderAndContent header="Tegn på læring" text={lesson.evaluation}/>
                        </div>
                    </div>
                )}
            />
        )

    }
}
const mapStateToProps: MapStateToProps<{
    lessonsState: LessonsState,
    isAdmin: boolean,
}, void, All> =
    (state: All) => ({lessonsState: state.lessonsState, isAdmin: state.authentication.isAdmin})
export default withRouter(connect(mapStateToProps)(LessonPage))

const HeaderAndContent =
    ({header, text, files, href}: {header: string, text: string, files?: File[], href?: string}) => (
    text !== "" || (files && files.length > 0) ? (
        <div>
            { href ?
                <NavLink to={href}>
                    <h3
                        className={style({marginTop: 16, marginBottom: 16})}
                    >
                        {header}
                    </h3>
                </NavLink>
                :
                <h3
                    className={style({marginTop: 16, marginBottom: 16})}
                >
                    {header}
                </h3>
            }
            {
                text === "" ? null : (
                    <span dangerouslySetInnerHTML={{__html: text}}/>
                )
            }
            {
                files == null ? null : (
                    <FileLinks files={files}/>
                )
            }
        </div>
    ) : null
)
const FileLinks = (props: {files: File[]}) => (
    <ul>
        {
            props.files.map((file: File) => (
                <li
                    key={file.id}
                    className={style({
                        listStyleType: "none",
                    })}
                >
                    <a href={`/api/file/${file.id}`}>{file.name}</a>
                </li>
            ))
        }
    </ul>
)
const CategoryLinks = (props: {categories: CategoryReference[]}) => (
    <li className={style({listStyleType: "none"})} >
        {
            props.categories.map((cr: CategoryReference) =>
                <NavLink
                    key={cr.id}
                    to={`/theory/${cr.url}`}
                    className={style({
                        marginRight: 7,
                    })}
                >
                    {cr.title}
                </NavLink>,
            )
        }
    </li>
)


