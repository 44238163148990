import {connect, DispatchProp} from "react-redux"
import {RouteComponentProps, withRouter} from "react-router"
import {ObjectState, Status} from "../redux/globalState"
import * as React from "react"
import {TheoryEditObject, TheoryEditSubSection} from "../model/theoryModel"
import {style} from "typestyle"
import {TextArea, TextInput} from "../component/inputs"
import EditRow from "../component/EditRow"
import axios, {AxiosError, AxiosResponse} from "axios"
import {Link, NavLink} from "react-router-dom"
import Button from "@material-ui/core/Button"
import ObjectStateDisplay from "../component/ObjectStateDisplay"
import {showTempNotification} from "../redux/actionCreators"

type Props = DispatchProp<any> & RouteComponentProps<any>
type State = {
    objectState: ObjectState<TheoryEditObject>,
    nextSectionTitle: string | null,
    updatedTimestamp: Date | null,
}

class TheoryEditPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            objectState: {
                status: Status.NONE,
                object: null,
            },
            nextSectionTitle: null,
            updatedTimestamp: null,
        }
        document.title = "Matematikplanen - editer teori"
    }


    componentDidMount() {
        this.fetchDate()
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.match.params.id === this.props.match.params.id) {
            return
        }
        this.setState({
            objectState: {
                status: Status.NONE,
                object: null,
            },
            nextSectionTitle: null,
            updatedTimestamp: null,
        })
        this.fetchDate()
    }

    fetchDate() {
        this.setState({
            objectState: {
                status: Status.LOADING,
                object: null,
            },
        })
        axios.get(`/theory/edit/${this.props.match.params.id}`)
            .then((response: AxiosResponse) => {
                const object: TheoryEditObject = response.data
                this.setState({
                    objectState: {
                        status: Status.SUCCESS,
                        object,
                    },
                })
            }).catch((error: AxiosError) => {
            this.setState({
                objectState: {
                    status: Status.ERROR,
                    object: null,
                    error,
                },
            })
        })

    }

    render() {
        return (
            <ObjectStateDisplay
                state={this.state.objectState}
                content={(object: TheoryEditObject) => (
                    <div className={style({margin: 16})}>
                        <Button
                            component={(props: any) => (<Link to={`/theory/${object.url}`} {...props} />)}
                            color="primary"
                        >
                            Vis
                        </Button>
                        {object.parentId ?
                            <Button
                                component={(props: any) =>
                                    (<Link to={`/theory/edit/${object.parentId}`} {...props} />)}
                                color="primary"
                            >
                                Et niveau op
                            </Button>
                            : null
                        }
                        <h1> Editing Theory </h1>
                        <EditRow
                            label={"Titel"}
                        >
                            <TextInput
                                value={object.title}
                                onChange={(newValue) => {
                                    object.title = newValue!
                                    this.setState({
                                        objectState: {
                                            status: Status.SUCCESS,
                                            object,
                                        },
                                    })
                                }}
                            />
                        </EditRow>
                        <EditRow
                            label={"Text"}
                        >
                            <TextArea
                                value={object.text}
                                onChange={(newValue) => {
                                    object.text = newValue!
                                    this.setState({
                                        objectState: {
                                            status: Status.SUCCESS,
                                            object,
                                        },
                                    })
                                }}
                                rows={16}
                            />
                        </EditRow>
                        <EditRow
                            label={"Direkte underafsnit"}
                        >
                            <div>
                                {
                                    object.subSections.map((section: TheoryEditSubSection, index: number) => (
                                        <EditRow key={index} label={section.title} smallPadding={true}>
                                            <div>
                                                {section.id ?
                                                    <NavLink
                                                        to={`/theory/edit/${section.id}`}
                                                        className={style({marginRight: 8})}
                                                    >
                                                        edit
                                                    </NavLink> : null
                                                }
                                                <a
                                                    href=""
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        const nextStateSubSections = object.subSections.slice(0)
                                                            .filter((sub) => sub.title !== section.title)
                                                        const nextStateObject = Object.assign({}, object)
                                                        nextStateObject.subSections = nextStateSubSections
                                                        this.setState({
                                                            objectState: {
                                                                status: Status.SUCCESS,
                                                                object: nextStateObject,
                                                            },
                                                        })
                                                    }}
                                                >
                                                    Slet
                                                </a>
                                            </div>
                                        </EditRow>
                                    ))
                                }
                                <TextInput
                                    value={this.state.nextSectionTitle}
                                    onChange={(newValue) => {
                                        this.setState({
                                            nextSectionTitle: newValue!,
                                        })
                                    }}
                                    size={40}
                                />
                                <a
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (this.state.nextSectionTitle != null) {
                                            object.subSections.push({
                                                id: null,
                                                title: this.state.nextSectionTitle,
                                            })
                                            this.setState({
                                                objectState: {
                                                    status: Status.SUCCESS,
                                                    object,
                                                },
                                                nextSectionTitle: null,
                                            })
                                        }
                                    }}
                                    className={style({marginLeft: 8})}
                                >
                                    Tilføj
                                </a>
                            </div>
                        </EditRow>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.setState({
                                    objectState: {
                                        status: Status.LOADING,
                                        object: null,
                                    },
                                })
                                axios.put(`/theory/edit/${this.props.match.params.id}`, object)
                                    .then((response: AxiosResponse) => {
                                        switch (response.status) {
                                            case 200:
                                                this.setState({
                                                    objectState: {
                                                        status: Status.SUCCESS,
                                                        object: response.data,
                                                    },
                                                    nextSectionTitle: null,
                                                    updatedTimestamp: new Date(),
                                                })
                                                this.props.dispatch(showTempNotification("Siden er gemt"))
                                                break
                                            default:
                                                throw Error("unexpected code")

                                        }
                                    })
                                    .catch((error: AxiosError) => {
                                        this.setState({
                                            objectState: {
                                                status: Status.ERROR,
                                                object: null,
                                                error,
                                            },
                                        })
                                    })
                            }}
                        >
                            Opdater
                        </Button>
                        { this.state.updatedTimestamp != null ?
                            <div>
                                Sidst opdateret:
                                {this.state.updatedTimestamp != null ? this.state.updatedTimestamp.toDateString() : ""}
                            </div> : null
                        }
                    </div>
                )}
            />
        )
    }
}

export default withRouter(connect()(TheoryEditPage))
