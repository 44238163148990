import {RouterState} from "react-router-redux"
import {Categories, TokenData} from "../model/model"
import {Lesson} from "../model/lessonModel"
import {Theory} from "../model/theoryModel"
import {AxiosError} from "axios"

export enum Status {
    NONE, LOADING, ERROR, SUCCESS,
}

export type ObjectState<T> = {
    status: Status.NONE,
    object: null,
} | {
    status: Status.LOADING,
    object: T | null,
} | {
    status: Status.ERROR,
    object: null,
    error: AxiosError,
} | {
    status: Status.SUCCESS,
    object: T,
}

// complete global State
export type All = {
    authentication: Authentication;
    router: RouterState;
    categoryState: ObjectState<Categories>;
    lessonsState: LessonsState;
    theoryState: ObjectState<Theory>;
    tempNotification: TempNotificationState;
}

export type Authentication = {
    tokenData: TokenData | null, // user is logged in iff tokenData != null
    isAdmin: boolean,
}

export type LessonsState = {
    [key: string]: ObjectState<Lesson>;
}

export type TempNotificationState = string | null
