import {connect, DispatchProp} from "react-redux"
import * as React from "react"
import axios from "axios"
import {NavLink} from "react-router-dom"
import {style} from "typestyle"

class AdminPage extends React.Component<{}, {}> {

    constructor(props: DispatchProp<any>) {
        super(props)
        document.title = "Matematikplanen - Editer Indhold"
    }

    render() {
        const tableStyle = style({
            border: "thin solid black",
            width: 900,
        })
        const tdStyle = style({
            border: "thin solid black",
            width: "50%",
            padding: 10,

        })

        return (
            <div
                className={style({
                    margin: 16,
                })}
            >
                <div style={{marginBottom: 10}}><NavLink to={"users"}>Brugeroversigt</NavLink></div>
                <div style={{marginBottom: 10}}><NavLink to={"subgoal/new"}>Nyt delmål</NavLink></div>
                <div style={{marginBottom: 10}}><NavLink to={"lesson/new"}>Ny lektion</NavLink></div>
                <div style={{marginBottom: 10}}><NavLink to={"theory/edit/1"}>Editer teori</NavLink></div>

                <h1> snydeark </h1>
                <hr/>
                <h3>Kursiv</h3>
                kursiv kan startes med underscore og sluttes med underscore.
                <table className={tableStyle}><tbody>
                    <tr>
                        <td className={tdStyle}>I _believe_ every word.</td>
                        <td className={tdStyle}>I <em>believe</em> every word.</td>
                    </tr>
                </tbody></table>

                <hr/>
                <h3>Fed skrift</h3>
                Fed skrift kan startes med et * og sluttes med et *. <br/>
                Alternativt kan fed skrift startes med ** og sluttes med **.
                <table className={tableStyle}><tbody>
                    <tr>
                        <td className={tdStyle}>her er *fed skrift*, yeah</td>
                        <td className={tdStyle}>her er <b>fed skrift</b>, yeah</td>
                    </tr>
                    <tr>
                        <td className={tdStyle}> her er **også fed skrift**, yeah </td>
                        <td className={tdStyle}><b>også fed skrift</b>, yeah</td>
                    </tr>
                </tbody></table>

                <hr/>
                <h3>Punktopstilling</h3>
                Ved hvert nyt punkt brug et * efterfulgt af et mellemrum.
                <table className={tableStyle}><tbody>
                    <tr>
                        <td className={tdStyle}>* A first item <br/>* A second item <br/>* A third</td>
                        <td className={tdStyle}><ul>
                            <li>A first item</li>
                            <li>A second item</li>
                            <li>A third</li>
                        </ul></td>
                    </tr>
                </tbody></table>
                <br/>

                Eftersom * bruges både til punkopstilling og til fed skrift,
                giver det et problem når punktopstilling og fed skrift blandes sammen. <br/>
                Derfor: Hvis man vil blande punkoptilling og fed skrift sammen,
                er man nød til at bruge ** for at strarte og slutte fed skrift.
                <table className={tableStyle}><tbody>
                    <tr>
                        <td className={tdStyle}>
                            * **A first item** <br/>
                            * A second item <br/>
                            * **A third**
                        </td>
                        <td className={tdStyle}>
                            <ul>
                                <li><b>A first item</b></li>
                                <li>A second item</li>
                                <li><b>A third</b></li>
                            </ul>
                        </td>
                    </tr>
                </tbody></table>



                <hr/>
                <h3>Nummereret punktopstilling</h3>
                Ved hvert nyt punkt brug et # efterfulgt af et mellemrum.
                <table className={tableStyle}><tbody>
                    <tr>
                        <td className={tdStyle}>
                            # A first item
                            <br/> # A second item
                            <br/> # A third
                        </td>
                        <td className={tdStyle}>
                            <ol>
                                <li>A first item</li>
                                <li>A second item</li>
                                <li>A third</li>
                            </ol>
                        </td>
                    </tr>
                </tbody></table>


                <hr/>
                <h3>Specialtegn</h3>
                <table className={tableStyle}><tbody>
                    <tr>
                        <td className={tdStyle}>
                            2 &lt;times/&gt; 2&lt;times/&gt;3
                        </td>
                        <td className={tdStyle}>
                            <p>2 × 2×3</p>
                        </td>
                    </tr>
                    <tr>
                        <td className={tdStyle}>
                            &lt;less/&gt; <br/>
                            &lt;greater/&gt;
                        </td>
                        <td className={tdStyle}>
                            <p>&lt;<br/>&gt;</p>
                        </td>
                    </tr>
                    <tr>
                        <td className={tdStyle}>
                            &lt;up/&gt;<br/>
                            &lt;left/&gt;&lt;right/&gt;<br/>
                            &lt;down/&gt;
                        </td>
                        <td className={tdStyle}>
                            <p>↑<br/>←→<br/>↓</p>
                        </td>
                    </tr>
                </tbody></table>

                <hr/>
                <h3>Hævet skrift (og kvadrat) </h3>
                <span>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: "hævet skrift startes med &lt;sup&gt; og sluttes med &lt;/sup&gt;",
                        }}
                    /><br/><em>sup</em>er forkortelse for superscript</span>
                <table className={tableStyle}><tbody>
                    <tr>
                        <td className={tdStyle}>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: "der er hævet skrift &lt;sup&gt;her&lt;/sup&gt;.",
                                    }}
                                />
                                <br/>
                                <span dangerouslySetInnerHTML={{__html: "4&lt;sup&gt;2&lt;/sup&gt; = 16"}}/>
                            </p>
                        </td>
                        <td className={tdStyle}>
                            der er hævet skrift <sup>her</sup>. <br/>
                            4<sup>2</sup> = 16
                        </td>
                    </tr>
                </tbody></table>

                <hr/>
                <h3>Links</h3>
                For at lave at et link skrives først i anførselstegn den tekst der skal vises som link på skærmen. <br/>
                Dernæst skrives et colon efterfulgt af den URL som man vil linke til. <br/>
                URL'en er adressen på siden, og URL'en starter med http:// <br/>
                I nedenstående eksempel hopper man til http://google.com når man klikker på Google.
                <table className={tableStyle}><tbody>
                    <tr>
                        <td className={tdStyle}>
                            link til "Google":http://google.com
                        </td>
                        <td className={tdStyle}>
                            <p>link til <a href="http://google.com" title="">Google</a></p>
                        </td>
                    </tr>
                </tbody></table>
                Hvis man vil linke til en side på matematikplanen.dk er det ikke nødvendigt at skrive hele URL'en. <br/>
                Det er nok at skrive den del af URL'en som kommer efter http://matematikplanen.dk/
                <table className={tableStyle}><tbody>
                    <tr>
                        <td className={tdStyle}>
                            "dagens tal er 2":http://matematikplanen.dk/lesson/show/34 <br/>
                            <br/>
                            kan forkortes til "dagens tal er 2":lesson/show/34
                        </td>
                        <td className={tdStyle}>
                            <p>
                                <a href="http://matematikplanen.dk/lesson/show/34" title="">dagens tal er 2</a>
                                <br/><br/>  kan forkortes til <a href="lesson/show/34" title="">dagens tal er 2</a>
                            </p>
                        </td>
                    </tr>
                </tbody></table>

                <hr/>
                <h3>Indsæt et billede</h3>
                For at indsætte et billede skrives billedets URL imellem to !-tegn
                <table className={tableStyle}><tbody>
                    <tr>
                        <td className={tdStyle}>
                            indsat billede !https://upload.wikimedia.org/wikipedia/commons/5/5a/Socks-clinton.jpg!
                        </td>
                        <td className={tdStyle}>
                            <p>indsat billede <img
                                src="https://upload.wikimedia.org/wikipedia/commons/5/5a/Socks-clinton.jpg"
                                alt=""
                            /></p>
                        </td>
                    </tr>
                </tbody></table>

            </div>
        )
    }
}
export default connect()(AdminPage)
