import { cssRule, forceRenderStyles } from "typestyle"
import {NestedCSSProperties} from "typestyle/src/types"

export const createStylesheet = () => {

    cssRule("body", {
        fontFamily: `verdana, arial, helvetica, sans-serif`,
    })

    const textCss: NestedCSSProperties = {
        margin: 0,
        color: "#333",
    }

    cssRule("body, p, ul, ol, td", textCss, {
        fontSize: 13,
        lineHeight: "18px",
    })

    cssRule("h1", textCss, {
        fontSize: "26px",
        lineHeight: "48px",
        fontWeight: "bold",
    })

    cssRule("h3", textCss, {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "bold",
    })

    cssRule("a", {
        color: "#000",
        margin: 0,
    })
    cssRule("a:hover", {
        color: "#fff",
        backgroundColor: "#000",
    })
    cssRule(".active", {
        fontWeight: "bold",
    })

    forceRenderStyles()

}

export const getColorByCategoryName = (categoryName: string) => {
    switch (categoryName) {
        case "Tal":
            return "#FFFF88"
        case "Måling":
            return "#FF8070"
        case "Geometri":
            return "#CDEB8B"
        case "Statistik og sandsynlighed":
            return "#C3D9FF"
        case "Evaluering":
            return "#FFE050"
        case "Supplerende materialer":
            return "#F9D7ED"
        default:
            throw Error(`No matching color by category name ${categoryName}`)
    }
}
