export class Theory {
    title: string
    url: string
    text: string
    showInIndex: boolean
    subSections: Theory[]
    parent: Theory | null

    constructor(json: any, parent: Theory | null) {
        this.title = json.title
        this.url = json.url
        this.text = json.text
        this.showInIndex = json.showInIndex
        this.subSections = json.subSections.map((s: any) => new Theory(s, this))
        this.parent = parent
    }

    siblings(): Theory[] {
        if (this.parent == null) {
            return []
        }
        return this.parent.subSections
    }

    nextSibling(): Theory | null {
        let foundSelf = false
        for (const t of this.siblings()) {
            if (foundSelf) {
                return t
            }
            if (t.title === this.title) {
                foundSelf = true
            }
        }
        return null
    }

    previousSibling(): Theory | null {
        let foundSelf: boolean = false
        const size = this.siblings().length
        for (let i = size - 1; i >= 0; i--) {
            if (foundSelf) {
                return this.siblings()[i]
            }
            if (this.siblings()[i].title === this.title) {
                foundSelf = true
            }
        }
        return null
    }

    previous(): Theory | null {
        let node = this.previousSibling()
        if (node == null) {
            return this.parent
        }
        while (node.subSections.length > 0 && node.subSections[node.subSections.length - 1].showInIndex) {
            node = node.subSections[node.subSections.length - 1]
        }
        return node
    }

    next(): Theory | null {
        if (this.subSections.length > 0 && this.subSections[0].showInIndex) {
            return this.subSections[0]
        }
        let node: Theory = this
        while (node.nextSibling() == null) {
            if (node.parent == null) {
                return null
            }
            node = node.parent
        }
        return node.nextSibling()
    }
}

export type TheoryEditSubSection = {
    id: string | null;
    title: string;
}
export type TheoryEditObject = {
    parentId: string | null;
    url: string;
    title: string;
    text: string;
    subSections: TheoryEditSubSection[];
}

