import * as queryString from "query-string"

/*
Wrapper around queryString.stringify
Removes unused member properties before stringifying
 */
export const stringify: (obj: object) => string = (obj: object) => {

    const returnObj: any = {}

    for (const prop in obj) {
        if (obj[prop] != null ) {
            const element: object = obj[prop]
            returnObj[prop] = obj[prop]
        }
    }
    return queryString.stringify(returnObj)
}

/*
wrapper around queryString.parse
Strips input for ?
 */
export const parse: (str: string) => any = (str: string) => {
    return queryString.parse(str.slice(1))
}
