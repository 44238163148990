import * as React from "react"
import {ReactElement} from "react"
import {ObjectState, Status} from "../redux/globalState"
import AxiosErrorView from "./AxiosErrorView"
import LoadingView from "./LoadingView"

const ObjectStateDisplay =
    <T extends {}>(props: {
        state: ObjectState<T>,
        content: (object: T) => ReactElement<any> | Array<ReactElement<any>>,
    }) => {
        if (props.state.status === Status.ERROR) {
            return (
                <AxiosErrorView error={props.state.error} />
            )
        } else if (props.state.status === Status.LOADING || props.state.status === Status.NONE) {
            return (
                <LoadingView />
            )
        } else {
            return (
                <div>{props.content(props.state.object)}</div>
            )
        }
    }

export default ObjectStateDisplay
