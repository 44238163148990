import {RouteComponentProps, withRouter} from "react-router"
import * as React from "react"
import {connect, DispatchProp, MapStateToProps} from "react-redux"
import {fetchCategories} from "../redux/actionCreators"
import {Categories, Category, MainCategory, ShortLesson, SubCategory, SubGoal} from "../model/model"
import {NavLink} from "react-router-dom"
import {parse} from "../util/StringUtil"
import {All, Authentication, ObjectState, Status} from "../redux/globalState"
import {getColorByCategoryName} from "../util/Stylesheet"
import {style} from "typestyle"
import {supplementMaterialsCategoryId} from "../const/const"
import ObjectStateDisplay from "../component/ObjectStateDisplay"

type Props = {
    categoryState: ObjectState<Categories>,
    isAdmin: boolean,
} & DispatchProp<any> & RouteComponentProps<any>
const defaultSearchParams = {
    id: "1",
    period: "1",
}
class CategoriesPage extends React.Component<Props, {}> {

    constructor(props: Props) {
        super(props)
        document.title = "Matematikplanen - kategorier"
    }

    componentDidMount() {
        this.props.dispatch(fetchCategories(this.props.categoryState))
    }

    render() {
        return (
            <ObjectStateDisplay
                state={this.props.categoryState}
                content={(object: Categories) => {
                    const querySearchParams =
                        Object.assign({}, defaultSearchParams, parse(this.props.history.location.search))
                    const selectedCategoryId: string = querySearchParams.id
                    const selectedPeriod: string = querySearchParams.period

                    return (
                        <CategoryNavigator
                            categories={object.mainCategories}
                            selectedCategoryId={selectedCategoryId}
                            selectedPeriod={+selectedPeriod}
                            isAdmin={this.props.isAdmin}
                        />
                    )
                }}
            />
        )

    }
}
const mapStateToProps: MapStateToProps<{
    categoryState: ObjectState<Categories>,
    isAdmin: boolean,
}, void, All> =
    (state: All, ownProps: void) => ({
        categoryState: state.categoryState,
        isAdmin: state.authentication.isAdmin,
    })
export default withRouter(connect(mapStateToProps)(CategoriesPage))

const CategoryNavigator = (props: {
    categories: MainCategory[],
    selectedCategoryId: string,
    selectedPeriod: number,
    isAdmin: boolean,
}) => {

    const {mainCategory, subCategory, subGoal}:
        {mainCategory: MainCategory, subCategory: SubCategory | null, subGoal: SubGoal | null} =
        getSelectedCategories(props.categories, props.selectedCategoryId, props.selectedPeriod)
    const lessons: ShortLesson[] = subGoal != null ? subGoal.lessons :
        (subCategory != null ?
            subCategory.lessonDescendants(props.selectedPeriod)
            :
            mainCategory.lessonDescendants(props.selectedPeriod)
        )

    const goalsInMainCategory: SubGoal[] | null = mainCategory.goals(props.selectedPeriod)
    const goalsInSelectedCategory: SubGoal[] | null =
        subCategory != null ? subCategory.goals(props.selectedPeriod) : goalsInMainCategory

    const goalListHeadingLabel =
        `Mål for ${subCategory != null ? subCategory.title : mainCategory.title} i periode ${props.selectedPeriod}`
    return (
        <table
            cellSpacing="3px"
            style={{width: "100%"}}
        >
            <tbody>
            <tr>
                <td
                    style={{verticalAlign: "top", width: "40%"}}
                >
                    {
                        <PeriodList
                            selectedPeriod={props.selectedPeriod}
                            periodIndependentCategoryId={subCategory != null ? subCategory.id : mainCategory.id}
                        />
                    }
                    {<table style={{width: "100%", border: "0"}}>
                        <tbody>
                        <CategoryList
                            categories={props.categories}
                            periodId={props.selectedPeriod}
                            selectedCategoryId={mainCategory.id}
                            color={null}

                        />
                        </tbody>
                    </table>}
                    {mainCategory.subCategories != null ?
                        <table style={{width: "100%", border: "0"}}>
                        <tbody>
                        <CategoryList
                            categories={mainCategory.subCategories.filter((c) =>
                                (c.goals(props.selectedPeriod) != null && c.goals(props.selectedPeriod)!.length > 0) ||
                                c.lessonDescendants(props.selectedPeriod) != null &&
                                c.lessonDescendants(props.selectedPeriod)!.length > 0,
                            )}
                            periodId={props.selectedPeriod}
                            selectedCategoryId={subCategory != null ? subCategory.id : null}
                            color={mainCategory.title}
                        />
                        </tbody>
                    </table>
                    : null}
                    {goalsInSelectedCategory != null ?
                        <h3
                            className={style({marginTop: 16})}
                        >
                            {goalListHeadingLabel}
                        </h3>
                        : null
                    }
                    {goalsInSelectedCategory != null ?
                        <GoalList
                            goals={goalsInSelectedCategory!}
                            color={mainCategory.title}
                            selectedCategoryId={props.selectedCategoryId}
                            period={props.selectedPeriod}
                            editLinks={props.isAdmin}
                        />
                        : null}
                </td>
                <td style={{width: "4%"}}/>
                <td style={{verticalAlign: "top", width: "48%"}}>
                    {props.selectedCategoryId !== supplementMaterialsCategoryId ?
                        <table><tbody>
                        <tr>
                            <td style={{width: "25%", padding: "15px", borderRight: "2px dashed"}}>
                                <h3>Lektioner</h3>
                            </td>
                            <td style={{width: "25%", padding: "15px"}}><h3>Supplerende Lektioner</h3></td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign: "top", padding: "15px", borderRight: "2px dashed"}}>
                                {
                                    lessons
                                        .filter((lesson) => lesson.important)
                                        .map((lesson) => (
                                            <LessonBox
                                                key={lesson.id}
                                                lesson={lesson}
                                                color={mainCategory.title}
                                            />
                                        ))
                                }
                            </td>
                            <td style={{verticalAlign: "top", padding: "15px"}}>
                                {
                                    lessons
                                        .filter((lesson) => !lesson.important)
                                        .map((lesson) => (
                                            <LessonBox
                                                key={lesson.id}
                                                lesson={lesson}
                                                color={mainCategory.title}
                                            />
                                        ))
                                }
                            </td>
                        </tr>
                        </tbody></table>
                        : null
                    }
                </td>
            </tr>
            </tbody>
        </table>
    )
}

const getSelectedCategories =
    (
        mainCategories: MainCategory[],
        selectedCategoryId: string,
        selectedPeriod: number,
    ): { mainCategory: MainCategory, subCategory: SubCategory | null, subGoal: SubGoal | null } => {
        let mainCategory: MainCategory | null = null
        let subCategory: SubCategory | null = null
        let subGoal: SubGoal | null = null

        for (const m of mainCategories) {
            if (m.id === selectedCategoryId) {
                mainCategory = m
                subCategory = null
                subGoal = null
            }

            if (m.subCategories != null) {
                for (const s of m.subCategories) {
                    if (s.id === selectedCategoryId) {
                        mainCategory = m
                        subCategory = s
                        subGoal = null
                    }
                    const sGoals = s.goals(selectedPeriod)
                    if (sGoals != null) {
                        for (const g of sGoals) {
                            if (g.id === selectedCategoryId) {
                                mainCategory = m
                                subCategory = s
                                subGoal = g
                            }
                        }
                    }
                }
            }
        }
        if (mainCategory == null) {
            throw Error(`selectedCategoryId=${selectedCategoryId} not matching any category`)
        }
        return {
            mainCategory: mainCategory!!,
            subCategory,
            subGoal,
        }
    }

const categoryPageUrl = (newPeriod: number, newCategoryId: string) =>
    `/category?id=${newCategoryId}&period=${newPeriod}`

// periodIndependentCategoryId is mainCategory, or SubCategory if selected
const PeriodList =
    connect()((props: {selectedPeriod: number, periodIndependentCategoryId: string} & DispatchProp<any>) => (
        <div>
            <span>Periode: </span>
            {[1, 2, 3, 4, 5, 6].map((period) =>
                props.selectedPeriod === period ?
                    <b
                        key={period}
                        style={{"marginRight": "7px"}}
                    >
                        {period}
                    </b> :
                    <NavLink
                        key={period}
                        to={categoryPageUrl(period, props.periodIndependentCategoryId)}
                        style={{"marginRight": "7px"}}
                    >
                        {period}
                    </NavLink>,
            )}
        </div>
))

const CategoryList = (props: {
    categories: Category[],
    periodId: number,
    selectedCategoryId: string | null,
    color: string | null,
}) => (
    <tr className="categoryList">
        {props.categories.map((category) => (
            <CategorySelector
                text={category.title}
                periodId={props.periodId}
                categoryId={category.id}
                isSelected={props.selectedCategoryId === category.id}
                color={props.color !== null ? props.color : category.title}
                key={category.id}
            />
        ))}
    </tr>
)

const CategorySelector = connect()((props: {
    color: string,
    isSelected: boolean,
    text: string,
    periodId: number,
    categoryId: string,
} & DispatchProp<any> ) => (
    <td
        style={{backgroundColor: getColorByCategoryName(props.color)}}
    >
        <NavLink to={categoryPageUrl(props.periodId, props.categoryId)}>
            {props.isSelected ? <b>{props.text}</b> : props.text}
        </NavLink>
    </td>
))

const GoalList = (props: {
    color: string,
    goals: SubGoal[],
    selectedCategoryId: string,
    period: number,
    editLinks: boolean,
}) => (
    <div>{props.goals.map((goal) => (
        <span key={goal.id}>
            <GoalBox
                color={props.color}
                goal={goal}
                isSelected={props.selectedCategoryId === goal.id}
                period={props.period}
                editLink={props.editLinks}
            />
            <br/>
        </span>
    ))}
    </div>
)

type GoalBoxProps = {
    goal: SubGoal,
    isSelected: boolean,
    color: string,
    period: number,
    editLink: boolean,
} & DispatchProp<any>
type GoalBoxState = {
    open: boolean,
}

const GoalBox = connect()(class extends React.Component<GoalBoxProps, GoalBoxState> {

    constructor(props: GoalBoxProps) {
        super(props)
        this.state = {open: false}
    }

    render() {
        const image = this.state.open ? require("../../assets/images/minus_black.gif") :
            require("../../assets/images/plus_black.gif")
        return (
            <table
                style={{width: "100%", border: 0, backgroundColor: getColorByCategoryName(this.props.color)}}
            >
                <tbody>
                <tr>
                    <td
                        style={{verticalAlign: "top", paddingTop: "4px", width: "4%"}}
                    >
                        <a onClick={() => {this.setState({open: !this.state.open})}}>
                            <img src={image}/>
                        </a>
                    </td>
                    <td
                        style={{width: "92%"}}
                    >
                        <div>
                            <NavLink to={categoryPageUrl(this.props.period, this.props.goal.id)}>
                                {(this.props.isSelected) ? <b>{this.props.goal.title}</b> : this.props.goal.title}
                            </NavLink>
                            {this.state.open ?
                                <span dangerouslySetInnerHTML={{__html: this.props.goal.description}}/> : null
                            }
                        </div>
                    </td>
                    {this.props.editLink ?
                        <td>
                            <NavLink to={`subgoal/edit/${this.props.goal.id}`}>Edit</NavLink>
                        </td> : null
                    }
                </tr>
                </tbody>
            </table>
        )
    }
})

type LessonBoxProps = {
    lesson: ShortLesson,
    color: string,
}
type LessonBoxState = {
    open: boolean,
}
class LessonBox extends React.Component<LessonBoxProps, LessonBoxState> {

    constructor(props: LessonBoxProps) {
        super(props)
        this.state = {open: false}
    }

    render() {
        const image = this.state.open ? require("../../assets/images/minus_black.gif") :
            require("../../assets/images/plus_black.gif")
        const star = require("../../assets/images/black_star.gif")
        const lesson = this.props.lesson
        const color = this.props.color
        const id = lesson.id

        return (
            <div
                style={{border: `thin solid black`, margin: `3px`, backgroundColor: getColorByCategoryName(color)}}
            >
                <table style={{width: "100%", border: "0"}}>
                    <tbody>
                    <tr>
                        <td style={{width: "4%", verticalAlign: `top`, paddingTop: `4px`}}>
                            <a onClick={() => {this.setState({open: !this.state.open})}}>
                                <img src={image}/>
                            </a>
                        </td>
                        <td style={{width: "92%"}}>
                            <div className={"lessonBoxText"}>
                                <NavLink to={`/lesson/${id}`}>{lesson.extendedTitle}</NavLink>
                                <br/>
                                {this.state.open ? <em>{lesson.summary}</em> : null}
                            </div>
                        </td>
                        <td style={{width: "4%"}}>
                            {lesson.important ?
                                <img height="15px" width="15px" src={star}/>
                                : null
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}
