import * as React from "react"
import {style} from "typestyle"
import {CategoryReference, File, Lesson, NewFile} from "../../model/lessonModel"
import EditRow from "../../component/EditRow"
import {
    Checkbox,
    Checkboxes,
    Dropdown,
    NumberInput,
    TextArea,
    TextInput,
} from "../../component/inputs"
import Button from "@material-ui/core/Button"
import {Categories, Category, MainCategory, SubCategory, SubGoal} from "../../model/model"

type Props = {
    lesson?: Lesson,
    categories: Categories
    submit: (lesson: Lesson) => void,
}
type Draft = {
    period: number | null,
    level: number | null,
    fields: Lesson,
}
type State = {
    draft: Draft,
    errorMessage: string | null,
}

export default class LessonEditPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        const emptyDraft: Draft = {
            period: null,
            level: null,
            fields: {
                period: 0,
                level: 0,
                title: "",
                extendedTitle: "",
                lessonObject: "",
                workform: "",
                materials: "",
                terminology: "",
                startActivity: "",
                activity: "",
                variations: "",
                supplement: "",
                ending: "",
                evaluation: "",
                summary: "",
                important: true,

                mainCategory: {
                    id: "1",
                    title: "Tal",
                    url: "",
                },
                subCategories: [],
                subGoals: [],
                secondaryCategories: [],
                competences: [],

                materialFiles: [],
                supplementFiles: [],
                otherFiles: [],

                newMaterialFiles: [],
                newSupplementFiles: [],
                newOtherFiles: [],
            },
        }

        this.state = {
            draft: props.lesson != null ? ({
                period: props.lesson!.period,
                level: props.lesson!.level,
                fields: props.lesson!,
            }) : emptyDraft,
            errorMessage: null,
        }
        document.title = "Matematikplanen - editer Lektion"
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
        this.setState({
            draft: {
                period: nextProps.lesson!.period,
                level: nextProps.lesson!.level,
                fields: nextProps.lesson!,
            },
            errorMessage: null,
        })
    }

    subCategories(mainCategories: MainCategory[], selectedMainCategory: CategoryReference): SubCategory[] {
        const subCategories: SubCategory[] | null = mainCategories
            .find((it: MainCategory) => it.id === selectedMainCategory.id)!
            .subCategories
        if (subCategories == null) {
            return []
        } else {
            return subCategories
        }
    }

    subGoalOptions(
        period: number | null,
        selectedSubCategories: CategoryReference[],
        subCategories: SubCategory[],
    ): CategoryReference[] {
        if (period == null) {
            return []
        }
        const filteredSubCategories: SubCategory[] = subCategories.filter(
            (sub: SubCategory) => selectedSubCategories
                    .find((selectedSub: CategoryReference) => sub.id === selectedSub.id) != null )

        const subGoals: SubGoal[][] = filteredSubCategories
            .map((category: SubCategory) => {
                const goals = category.goals(period)
                if (goals == null) {
                    return []
                } else {
                    return goals
                }
            })
        const subGoalsFlat: SubGoal[] = [].concat.apply([], subGoals)
        return subGoalsFlat.map((it: SubGoal) => ({
            id: it.id,
            title: it.title,
            url: "",
        }))
    }

    render() {

        const draft: Draft = this.state.draft

        const subCategories: SubCategory[] =
            this.subCategories(this.props.categories.mainCategories, this.state.draft.fields.mainCategory)

        const subCategoryOptions = subCategories.map((category: SubCategory) => ({
            id: category.id,
            title: category.title,
            url: "",
        }))

        return (
            <div>
                <EditRow
                    label="Titel"
                >
                    <TextInput
                        value={draft.fields.title}
                        onChange={(newValue) => {
                            draft.fields.title = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                    />
                </EditRow>
                <EditRow
                    label="Periode"
                >
                    <NumberInput
                        value={draft.period}
                        onChange={(newValue) => {
                            draft.period = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                    />
                </EditRow>
                <EditRow
                    label="Niveau"
                >
                    <NumberInput
                        value={draft.level}
                        onChange={(newValue) => {
                            draft.level = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                    />
                </EditRow>
                <EditRow
                    label="Hovedkategori"
                >
                    <Dropdown
                        value={draft.fields.mainCategory}
                        options={
                            this.props.categories.mainCategories
                                .map((category: MainCategory) => ({
                                    id: category.id,
                                    title: category.title,
                                    url: "",
                                }))}
                        valueToDisplayString={(c) => c.title}
                        onChange={(category: CategoryReference) => {
                            draft.fields.mainCategory = category
                            this.setState({
                                draft,
                            })
                        }}
                        disabled={draft.fields.subCategories.length > 0 || draft.fields.secondaryCategories.length > 0}
                    />

                </EditRow>
                <EditRow
                    label="Underkategorier"
                >
                    <Checkboxes
                        value={draft.fields.subCategories}
                        options={subCategoryOptions}
                        valueToDisplayString={(c) => c.title}
                        onChange={(newValue: CategoryReference[]) => {
                            draft.fields.subCategories = newValue
                            this.setState({
                                draft,
                            })
                        }}
                        disabled={draft.fields.subGoals.length > 0}
                    />
                </EditRow>
                <EditRow
                    label="Delmål"
                >
                    <Checkboxes
                        value={this.state.draft.fields.subGoals}
                        options={this.subGoalOptions(draft.period, draft.fields.subCategories, subCategories)}
                        valueToDisplayString={(c) => c.title}
                        onChange={(newValue: CategoryReference[]) => {
                            draft.fields.subGoals = newValue
                            this.setState({
                                draft,
                            })
                        }}
                        disabled={false}
                    />
                </EditRow>

                <EditRow
                    label="Sekundære kategorier"
                >
                    <Checkboxes
                        value={draft.fields.secondaryCategories}
                        options={
                            this.props.categories.mainCategories
                                .filter(
                                    (it: MainCategory) => it.id !== draft.fields.mainCategory.id )
                                .map((category: MainCategory) => ({
                                    id: category.id,
                                    title: category.title,
                                    url: "",
                                }))}
                        valueToDisplayString={(c) => c.title}
                        onChange={(newValue: CategoryReference[]) => {
                            draft.fields.secondaryCategories = newValue
                            this.setState({
                                draft,
                            })
                        }}
                        disabled={false}
                    />
                </EditRow>
                <EditRow
                    label="Kompetencer"
                >
                    <Checkboxes
                        value={draft.fields.competences}
                        options={
                            this.props.categories.competences
                                .map((category: Category) => ({
                                    id: category.id,
                                    title: category.title,
                                    url: "",
                                }))}
                        valueToDisplayString={(c) => c.title}
                        onChange={(newValue: CategoryReference[]) => {
                            draft.fields.competences = newValue
                            this.setState({
                                draft,
                            })
                        }}
                        disabled={false}
                    />
                </EditRow>

                <EditRow
                    label="Lektionsmål"
                >
                    <TextArea
                        value={draft.fields.lessonObject}
                        onChange={(newValue) => {
                            draft.fields.lessonObject = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={10}
                    />
                </EditRow>
                <EditRow
                    label="Særlig vigtig lektion"
                >
                    <Checkbox
                        value={draft.fields.important}
                        text=""
                        onChange={(newValue) => {
                            draft.fields.important = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                    />
                </EditRow>
                <EditRow
                    label="Resume"
                >
                    <TextArea
                        value={draft.fields.summary}
                        onChange={(newValue) => {
                            draft.fields.summary = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={5}
                    />
                </EditRow>
                <EditRow
                    label="Arbejdsform"
                >
                    <TextInput
                        value={draft.fields.workform}
                        onChange={(newValue) => {
                            draft.fields.workform = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                    />
                </EditRow>
                <EditRow
                    label="Materiale filer"
                >
                    <FileGrid
                        files={draft.fields.materialFiles}
                        newFiles={draft.fields.newMaterialFiles}
                        onChange={(files: File[], newFiles: NewFile[]) => {
                            draft.fields.materialFiles = files
                            draft.fields.newMaterialFiles = newFiles
                            this.setState({
                                draft,
                            })
                        }}
                    />
                </EditRow>
                <EditRow
                    label="Materialer"
                >
                    <TextArea
                        value={draft.fields.materials}
                        onChange={(newValue) => {
                            draft.fields.materials = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={5}
                    />
                </EditRow>
                <EditRow
                    label="Ord og udtryk"
                >
                    <TextArea
                        value={draft.fields.terminology}
                        onChange={(newValue) => {
                            draft.fields.terminology = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={5}
                    />
                </EditRow>
                <EditRow
                    label="Startaktivitet"
                >
                    <TextArea
                        value={draft.fields.startActivity}
                        onChange={(newValue) => {
                            draft.fields.startActivity = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={20}
                    />
                </EditRow>
                <EditRow
                    label="Aktivitet"
                >
                    <TextArea
                        value={draft.fields.activity}
                        onChange={(newValue) => {
                            draft.fields.activity = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={20}
                    />
                </EditRow>
                <EditRow
                    label="Differentiering / variationer"
                >
                    <TextArea
                        value={draft.fields.variations}
                        onChange={(newValue) => {
                            draft.fields.variations = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={10}
                    />
                </EditRow>
                <EditRow
                    label="Supplerende aktiviteter"
                >
                    <TextArea
                        value={draft.fields.supplement}
                        onChange={(newValue) => {
                            draft.fields.supplement = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={10}
                    />
                </EditRow>
                <EditRow
                    label="Supplerende aktiviteter filer"
                >
                    <FileGrid
                        files={draft.fields.supplementFiles}
                        newFiles={draft.fields.newSupplementFiles}
                        onChange={(files: File[], newFiles: NewFile[]) => {
                            draft.fields.supplementFiles = files
                            draft.fields.newSupplementFiles = newFiles
                            this.setState({
                                draft,
                            })
                        }}
                    />
                </EditRow>
                <EditRow
                    label="Afslutning"
                >
                    <TextArea
                        value={draft.fields.ending}
                        onChange={(newValue) => {
                            draft.fields.ending = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={20}
                    />
                </EditRow>
                <EditRow
                    label="Tegn på læring"
                >
                    <TextArea
                        value={draft.fields.evaluation}
                        onChange={(newValue) => {
                            draft.fields.evaluation = newValue!
                            this.setState({
                                draft,
                            })
                        }}
                        rows={10}
                    />
                </EditRow>
                <EditRow
                    label="Andre filer tilhørende lektionen"
                >
                    <FileGrid
                        files={draft.fields.otherFiles}
                        newFiles={draft.fields.newOtherFiles}
                        onChange={(files: File[], newFiles: NewFile[]) => {
                            draft.fields.otherFiles = files
                            draft.fields.newOtherFiles = newFiles
                            this.setState({
                                draft,
                            })
                        }}
                    />
                </EditRow>
                <div
                    className={style({
                        marginTop: 16,
                        marginBottom: 16,
                    })}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (draft.fields.title === null) {
                                alert("Titel skal være sat")
                                return
                            }
                            if (draft.period === null || draft.period < 1 || draft.period > 7) {
                                alert("Periode skal være sat")
                                return
                            }
                            if (draft.level === null) {
                                alert("Niveau skal være sat")
                                return
                            }
                            if (!filesValid(draft.fields.materialFiles) ||
                                !newFilesValid(draft.fields.newMaterialFiles)) {
                                alert("Navn og rækkefølge skal være sat i alle MaterialeFiler")
                                return
                            }
                            draft.fields.period = draft.period
                            draft.fields.level = draft.level
                            this.props.submit(draft.fields)
                        }}
                    >
                        {this.props.lesson ? "Opdater" : "Opret"}
                    </Button>
                </div>
            </div>
        )

    }
}

const filesValid = (files: File[]) => {
    for (const file of files) {
        if (file.order == null || file.name == null) {
            return false
        }
    }
    return true
}

const newFilesValid = (files: NewFile[]) => {
    for (const file of files) {
        if (file.order == null || file.name == null) {
            return false
        }
    }
    return true
}


const FileRow = (props: {
    id?: string,
    name: string,
    order: number,
    onNameChanged: (newName: string) => void,
    onOrderChanged: (newOrder: number) => void,
    onDelete: () => void,
}) => (
    <div
        className={style({display: "flex", marginBottom: 4})}
    >
        <TextInput
            value={props.name}
            onChange={(newValue) => {
                props.onNameChanged(newValue!)
            }}
        />
        <NumberInput
            value={props.order}
            onChange={(newValue) => {
                props.onOrderChanged(newValue!)
            }}
        />
        <span
            className={style({marginLeft: 8, width: 120})}
        >
            {props.id != null ? `/api/file/${props.id}` : ""}
        </span>
        <a
            href=""
            className={style({marginLeft: 8, marginRight: 8})}
            onClick={(e) => {
                e.preventDefault()
                props.onDelete()
            }}
        >
            Delete
        </a>
        {props.id != null ?
            <a
                href={`/api/file/${props.id}`}
            >
                Show
            </a> : null
        }
    </div>
)



const FileGrid =
    (props: { files: File[], newFiles: NewFile[], onChange: (files: File[], newFiles: NewFile[]) => void }) => {
        return (
            <div>
                {
                    props.files.map((file: File, index: number) => (
                        <div
                            key={index}
                        >
                            <FileRow
                                id={file.id}
                                name={file.name}
                                order={file.order}
                                onNameChanged={(newName) => {
                                    file.name = newName!
                                    props.onChange(props.files, props.newFiles)
                                }}
                                onOrderChanged={(newOrder) => {
                                    file.order = newOrder!
                                    props.onChange(props.files, props.newFiles)
                                }}
                                onDelete={() => {
                                    const array = props.files.slice()
                                    array.splice(index, 1)
                                    props.onChange(array, props.newFiles)
                                }}
                            />
                        </div>
                    ))
                }
                {
                    props.newFiles.map((file: NewFile, index: number) => (
                        <div
                            key={index}
                        >
                            <FileRow
                                name={file.name}
                                order={file.order}
                                onNameChanged={(newName) => {
                                    file.name = newName!
                                    props.onChange(props.files, props.newFiles)
                                }}
                                onOrderChanged={(newOrder) => {
                                    file.order = newOrder!
                                    props.onChange(props.files, props.newFiles)
                                }}
                                onDelete={() => {
                                    const array = props.newFiles.slice()
                                    array.splice(index, 1)
                                    props.onChange(props.files, array)
                                }}
                            />
                        </div>
                    ))
                }
                <input
                    type="file"
                    className={style({marginTop: 16})}
                    onChange={(event) => {
                        if (event.target.files == null) {
                            return
                        }
                        const file = event.target.files[0]
                        const name = file.name
                        const type = file.type

                        const reader = new FileReader()

                        reader.onload = () => {
                            const fileString = reader.result as string
                            const newFile: NewFile = {
                                filename: name,
                                contentType: type,
                                contentAsBase64: fileString,
                                name,
                                order: findHighestOrder(props.files, props.newFiles),
                            }
                            props.newFiles.push(newFile)
                            props.onChange(props.files, props.newFiles)
                        }
                        reader.readAsDataURL(file)
                    }}

                />
            </div>
        )
    }

const findHighestOrder = (files: File[], newFiles: NewFile[]) => {
    let result: number = 0
    for (const file of files) {
        if (file.order > result) {
            result = file.order
        }
    }
    for (const file of newFiles) {
        if (file.order > result) {
            result = file.order
        }
    }
    return result + 1
}
